import React, { Children, Component } from 'react'
import { Card, Frame } from '@shopify/polaris'
import './LoginCard.scss'
import logo from '../../assets/logo.svg'

export class LoginCard extends Component {
  render() {
    const titleMarkup = (
      <>
        <img className="fLogo" src={ logo } alt="ParcelPanel Affiliate"/>
        <p className="subtitle">{ this.props.summary }</p>
      </>
    )

    const sectionMarkup = Children.map(
      this.props.children,
      child => child && <Card.Section>{ child }</Card.Section>,
    )

    return (
      <Frame>
        <div className="login">
          <div className="login-card-wrap">
            <Card>
              <Card.Header
                title={ titleMarkup }
              />
              { sectionMarkup }
            </Card>
          </div>
        </div>
      </Frame>
    )
  }
}