import React, { Component } from 'react'
import { Button, Card, FormLayout, Modal, Select, Stack, TextField, Toast } from '@shopify/polaris'
import { reqAccountChangePassword, reqSave } from '../../api/index'
import memory from '../../utils/memory'
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import { PasswordField } from '../../components'

const PASSWORD_FIELDS = [ 'new_password', 'confirm_new_password' ]

export class Account extends Component {

  constructor(props) {
    super(props)
    this.state = {
      user_info: memory.user_info,
      partner_type_option: memory.setting.partner_type,

      first_name: memory.user_info.first_name,
      last_name: memory.user_info.last_name,
      partner_type: memory.user_info.partner_type,
      email: memory.user_info.email,
      paypal_address: memory.user_info.paypal_address,

      isEnabledSaveButton: false,

      first_name_error: false,
      last_name_error: false,

      loading: false,

      // 查看明文密码
      viewPlaintextPassword: false,

      loadingChangePassword: false,
      new_password: '',
      confirm_new_password: '',
      new_password_error: '',
      confirm_new_password_error: '',

      isActiveChangePasswordModal: false,

      toast: {
        active: false,
        content: '',
        error: false,
      },
    }
  }

  showToast(content, error = false) {
    this.setState({
      toast: {
        active: true,
        content,
        error,
      },
    })
  }

  dismissToast = () => {
    this.setState({
      toast: {
        active: false,
      },
    })
  }

  verifyChangePassword() {
    let success = true
    const pattern1 = /(?=.*[A-Z])/g;
    const pattern2 = /(?=.*[a-z])/g;
    const pattern3 = /(?=.*[0-9])/g;

    if ('' === this.state.new_password) {
      this.setState({ new_password_error: 'New password can\'t be blank' })
      success = false
    } else if (this.state.new_password.length < 8) {
      this.setState({ new_password_error: 'Password is too short (minimum is 8 characters)' })
      success = false
    } else if (this.state.new_password !== this.state.confirm_new_password) {
      this.setState({ confirm_new_password_error: 'The password entered didn\'t match, please try again' })
      success = false
    }else if (!pattern1.test(this.state.new_password) || !pattern2.test(this.state.new_password) || !pattern3.test(this.state.new_password)) {
      success = false
      this.setState({new_password_error: 'Password have to use 8 more characters with a mix of uppercase and lowercase letters & numbers.'})
    }

    return success
  }

  openChangePasswordModal = () => {
    if (!this.state.loadingChangePassword) {
      PASSWORD_FIELDS.map(v => this.setState({ [v]: '', [`${ v }_error`]: '' }))
    }

    this.setState({ isActiveChangePasswordModal: true })
  }

  onConfirmChangePasswordModal = () => {
    if (!this.verifyChangePassword()) {
      return false
    }

    this.setState({ loadingChangePassword: true })

    let { new_password, email } = this.state

    const encodedPassword = Base64.stringify(Utf8.parse(new_password))

    reqAccountChangePassword({ email, new_password: encodedPassword }).then(
      resp => {
        setTimeout(() => {
          // 1秒后退出登录（主要是给用户看一下修改成功的提示）
          this.props.onLogout()
        }, 1e3)

        this.showToast(resp.data.msg)
      },
    ).catch(
      error => {
        if (error.response.data.errors?.new_password?.[0]) {
          this.setState({ new_password_error: error.response.data.errors.new_password[0] })
        } else {
          this.showToast(error.response.data.msg, true)
        }
        this.setState({ loadingChangePassword: false })
      },
    )
  }

  onCloseChangePasswordModal = () => {
    this.setState({ isActiveChangePasswordModal: false })
  }

  validateUserInfo = () => {
    let success = true

    if ('' === this.state.first_name) {
      success = false
      this.setState({ first_name_error: 'First name can\'t be blank' })
    }

    if ('' === this.state.last_name) {
      success = false
      this.setState({ last_name_error: 'Last name can\'t be blank' })
    }

    if ('' === this.state.partner_type) {
      success = false
      this.setState({ partner_type_error: 'Partner type can\'t be blank' })
    }

    return success
  }

  handleInputChange = (val, name) => {

    if (-1 !== [ 'first_name', 'last_name', 'partner_type' ].indexOf(name)) {
      if ('' !== val && val !== this.state.user_info[name].toString()) {
        this.setState({ isEnabledSaveButton: true })
      } else {
        this.setState({ isEnabledSaveButton: false })
      }
    } else if (-1 !== [ 'new_password', 'confirm_new_password' ].indexOf(name)) {
      this.setState({ [`${ name }_error`]: '' })
    }

    this.setState({
      [name]: val,
    })
  }

  saveUserInfo = () => {
    if (!this.validateUserInfo()) {
      return false
    }

    const { first_name, last_name, partner_type } = this.state

    this.setState({ loading: true })

    reqSave({ first_name, last_name, partner_type }).then(
      resp => {
        this.showToast(resp.data.msg)

        this.setState(
          {
            loading: false,
            isEnabledSaveButton: false,
          },
          () => {
            /* 维护用户信息 */
            this.props.onUserInfoUpdate({
              ...memory.user_info,
              first_name, last_name, partner_type,
              partner_type_text: this.state.partner_type_option[partner_type].label,
            })
            this.setState({ user_info: memory.user_info })
          },
        )
      },
    ).catch(
      error => {
        if (401 === error.response?.status) {
          this.props.history.replace('/login')
        }
      },
    )
  }


  render() {
    const toastMarkup = this.state.toast.active ? (
      <Toast content={ this.state.toast.content } error={ this.state.toast.error } onDismiss={ this.dismissToast }/>
    ) : null

    return (
      <div className="page-warp">
        <h1 className="page-title">Account</h1>

        { toastMarkup }

        <div className="PP-FormCard">
          <Card title="General" sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  id="first_name" label="First name" value={ this.state.first_name } disabled={ this.state.loading }
                  onChange={ this.handleInputChange } error={ this.state.first_name_error } autoComplete="given-name"
                />
                <TextField
                  id="last_name" label="Last name" value={ this.state.last_name } disabled={ this.state.loading }
                  onChange={ this.handleInputChange } error={ this.state.last_name_error } autoComplete="family-name"
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <TextField id="email" value={ this.state.email } label="Account email" disabled autoComplete="username"/>
                  <TextField
                      error={this.state.user_info.is_show_warning === 1 ?
                          <div>Tips: Changing your password regularly will keep your account more secure.</div> : ''
                      }
                      type="password" label="Password" value="************" autoComplete="off" disabled
                      labelAction={ { content: 'Change password', onAction: this.openChangePasswordModal } }
                  />
              </FormLayout.Group>

              <FormLayout.Group>
                <Select
                  id="partner_type" label="Partner type" value={ `${ this.state.partner_type }` } options={ Object.values(this.state.partner_type_option) }
                  disabled={ this.state.loading }
                  onChange={ this.handleInputChange } error={ this.state.partner_type_error }
                />
                <TextField value={ this.state.paypal_address } label="PayPal" disabled autoComplete="off"/>
              </FormLayout.Group>
            </FormLayout>
          </Card>

          <div className="PP-FormCard-Footer">
            <Stack alignment="trailing" vertical>
              <Button loading={ this.state.loading } onClick={ this.saveUserInfo } primary disabled={ !this.state.isEnabledSaveButton }>Save changes</Button>
            </Stack>
          </div>
        </div>

        <Modal
          sectioned
          open={ this.state.isActiveChangePasswordModal }
          onClose={ this.onCloseChangePasswordModal }
          title="Change password"
          primaryAction={ {
            content: 'Confirm',
            onAction: this.onConfirmChangePasswordModal,
            loading: this.state.loadingChangePassword,
          } }
          secondaryActions={ [ {
            content: 'Cancel',
            onAction: this.onCloseChangePasswordModal,
          } ] }
        >
          <FormLayout>
            <PasswordField
              id="new_password"
              type="password"
              label="New password"
              disabled={ this.state.loadingChangePassword }
              value={ this.state.new_password }
              error={ this.state.new_password_error }
              onChange={ this.handleInputChange }
              autoComplete="new-password"
              viewPlaintext={ this.state.viewPlaintextPassword }
              onViewPlaintextClick={ this.handleViewPlaintextPasswordClick }
            />
            <PasswordField
              id="confirm_new_password"
              type="password"
              label="Confirm new password"
              disabled={ this.state.loadingChangePassword }
              value={ this.state.confirm_new_password }
              error={ this.state.confirm_new_password_error }
              onChange={ this.handleInputChange }
              autoComplete="new-password"
              viewPlaintext={ this.state.viewPlaintextPassword }
              onViewPlaintextClick={ this.handleViewPlaintextPasswordClick }
            />
          </FormLayout>
        </Modal>
      </div>
    )
  }


  handleViewPlaintextPasswordClick = (viewPlaintextPassword) => {
    this.setState({ viewPlaintextPassword })
  }
}

