import React, { Component } from 'react'
import { Button, Form, FormLayout, TextField, Toast } from '@shopify/polaris'
import { reqLogin, reqResetPassword, reqVerifyResetPasswordToken } from '../../../api/index'
import memory from '../../../utils/memory'
import { LoginCard, PasswordField } from '../../../components'
import storage from '../../../utils/storage'
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'

/**
 * 登陆的路由组件
 */
export class ResetPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      viewPlaintextPassword: false,

      email: '',
      password: '',
      passwordError: '',
      passwordConfirm: '',
      passwordConfirmError: '',
      loading: false,
      token: '',

      toast: {
        active: false,
        error: false,
        content: '',
      },
    }
  }

  componentDidMount() {
    const { search } = this.props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)
    const token = searchParams.get('token')
    const email = searchParams.get('email')
    this.setState({ token, email })

    if (token && email) {
      this.verifyToken(token, email)
    }
  }

  verifyToken(token, email) {
    reqVerifyResetPasswordToken(email, token).then(
      resp => {

      },
    ).catch(
      err => {
        this.setToastContent(err.response.data.msg, true)
        setTimeout(() => {this.props.history.replace('/login')}, 1000)
      },
    )
  }

  validateForm() {
    let pass = true

    // 必须同时包含 大小写字母+数字 8位字符
    const passwordRule = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;

    if ('' === this.state.password) {
      this.setState({ passwordError: 'Password can\'t be blank' })
      pass = false
    } else if (!passwordRule.test(this.state.password)) {
      this.setState({passwordError: 'Password have to use 8 more characters with a mix of uppercase and lowercase letters & numbers.'})
      pass = false
    } else if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ passwordConfirmError: 'The password entered didn\'t match, please try again' })
      pass = false
    }

    return pass
  }

  handleSubmit = (event) => {
    if (!this.validateForm()) {
      return false
    }

    const encodedPassword = passwordEncode(this.state.password)

    this.setState({ loading: true })

    reqResetPassword(this.state.email, this.state.token, encodedPassword)
      .then(
        resp => {
          this.setToastContent(resp.data.msg)
          // // 登录
          // this.login(this.state.email, encodedPassword)
          this.props.history.replace('/')
        },
      )
      .catch(
        error => {
          if (error.response.data.errors?.password?.[0]) {
            this.setState({ passwordError: error.response.data.errors.password[0] })
          } else {
            this.setToastContent(error.response.data.msg)
          }
          this.setState({ loading: false })
        },
      )
  }

  // login(email, password) {
  //   reqLogin(email, password).then(
  //     resp => {
  //       // 保存用户数据和全局配置文件到内存
  //       const setting = resp.data.data.setting
  //       memory.setting = setting
  //       storage.saveInfo('setting', setting)
  //
  //       const user_info = resp.data.data.user_info
  //       this.props.onLogin(user_info)
  //
  //       if (!user_info.is_email_verified) {
  //         this.jumpToVerifySignUpEmail()
  //       } else {
  //         this.props.history.replace('/')
  //       }
  //     },
  //   ).catch(
  //     err => {
  //       this.props.history.replace('/')
  //     },
  //   )
  // }
  //
  // jumpToVerifySignUpEmail() {
  //   this.props.history.push('/signup', { verifyEmail: true })
  // }

  setToastContent(content, error = false) {
    this.setState({
      toast: {
        ...this.state.toast,
        active: true,
        content,
        error,
      },
    })
  }

  renderToast() {
    return this.state.toast.active && (
      <Toast
        onDismiss={
          () => {
            this.setState({
              toast: {
                ...this.state.toast,
                active: false,
              },
            })
          }
        }
        { ...this.state.toast }
      />
    )
  }

  render() {
    return (
      <LoginCard summary="Howdy, please reset your password">
        <Form onSubmit={ this.handleSubmit }>
          <FormLayout>
            <TextField
              disabled
              type="email"
              value={ this.state.email }
              label="Account email"
              autoComplete="username"
            />

            <PasswordField
              id="password"
              value={ this.state.password }
              label="New password"
              type="password"
              onChange={ val => this.setState({ password: val, passwordError: '' }) }
              error={ this.state.passwordError }
              autoComplete="new-password"
              viewPlaintext={ this.state.viewPlaintextPassword }
              onViewPlaintextClick={ this.handleViewPlaintextPasswordClick }
            />

            <PasswordField
              id="confirmpwd"
              value={ this.state.passwordConfirm }
              label="Confirm new password"
              type="password"
              onChange={ val => this.setState({ passwordConfirm: val, passwordConfirmError: '' }) }
              error={ this.state.passwordConfirmError }
              autoComplete="new-password"
              viewPlaintext={ this.state.viewPlaintextPassword }
              onViewPlaintextClick={ this.handleViewPlaintextPasswordClick }
            />

            <Button loading={ this.state.loading } primary submit fullWidth>Change and log in</Button>
          </FormLayout>
          { this.renderToast() }
        </Form>
      </LoginCard>
    )
  }


  handleViewPlaintextPasswordClick = (viewPlaintextPassword) => {
    this.setState({ viewPlaintextPassword })
  }
}

function passwordEncode(value) {
  return Base64.stringify(Utf8.parse(value))
}
