import { Button, TextField } from '@shopify/polaris'
import { HideMinor, ViewMinor } from '@shopify/polaris-icons'
import React, { useCallback } from 'react'

export function PasswordField({
  suffix,
  viewPlaintext,
  onViewPlaintextClick,
  ...props
}) {

  const handleEyeClick = useCallback(() => {
    onViewPlaintextClick(!viewPlaintext)
  }, [ onViewPlaintextClick, viewPlaintext ])

  const eyeIcon = viewPlaintext ? HideMinor : ViewMinor

  const eyeMarkup = (
    <Button icon={ eyeIcon } onClick={ handleEyeClick } plain/>
  )

  const suffixMarkup = (
    <div
      style={ { display: 'flex' } }
      onClick={ stopPropagation }
    >
      { eyeMarkup }
    </div>
  )

  return (
    <TextField
      { ...props }
      suffix={ suffixMarkup }
      type={ viewPlaintext ? 'text' : 'password' }
    />
  )
}

function stopPropagation(event) {
  event.stopPropagation()
}
