import React, { Component } from 'react'
import { Button, Form, FormLayout, Link, TextContainer, TextField, Toast } from '@shopify/polaris'
import './ForgotPassword.scss'
import { reqSendResetPasswordEmail } from '../../../api/index'
import { LoginCard } from '../../../components'

export class ForgotPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      loading: false,
      emailError: false,
      isEmailSent: false,

      toast: {
        active: false,
        content: '',
        error: false,
      },

      toasts: [],
    }
  }

  showToast(content, error = false) {
    this.setState({
      toast: {
        active: true,
        content,
        error,
      },
    })
  }


  dismissToast = () => {
    this.setState({
      toast: {
        active: false,
      },
    })
  }

  resend = () => {
    this.handleSubmit()
  }

  // async 和await
  handleSubmit = (event) => {
    // this.setState({
    //   toasts: [...this.state.toasts, {
    //     id: new Date().getTime(),
    //     content: '123123' + Math.random(),
    //     onDismiss: (id) => {
    //       console.log(id)
    //       this.setState({
    //         toasts: this.state.toasts.filter(({id: _id}) => id !== _id),
    //       })
    //     },
    //   }],
    // })

    // this.setState({
    //   toasts: [...this.state.toasts, {
    //     content: Math.random(),
    //   }],
    // })

    if (this.state.email === '') {
      this.setState({emailError: 'Email can\'t be blank'})
      return false
    }

    this.setState({loading: true})

    reqSendResetPasswordEmail(this.state.email).then(
      resp => {
        this.showToast(resp.data.msg)
        this.setState({
          loading: false,
          isEmailSent: true,
        })
      },
    ).catch(
      error => {
        this.showToast(error.response.data.msg, true)
        this.setState({loading: false})
      },
    )
  }

  renderResetPasswordForm() {
    return (
      <>
        <Form onSubmit={ this.handleSubmit }>
          <FormLayout>
            <TextField
              value={ this.state.email }
              label="Account email"
              type="email"
              onChange={ val => this.setState({
                email: val,
                emailError: '',
              }) }
              error={ this.state.emailError }
              autoComplete="username"
            />
            <Button loading={ this.state.loading } primary submit fullWidth>Reset password</Button>
          </FormLayout>
        </Form>

        <div className="footerAction-wrap">
          <Link
            removeUnderline
            onClick={ () => this.props.history.replace('/login') }
          >
            Return to log in
          </Link>
        </div>
      </>
    )
  }

  renderEmailSent() {
    return (
      <TextContainer spacing="tight">
        <p>An email with instructions to reset your password has been sent to you, please check your inbox.</p>
        <p>If you have not received, please check your spam, or <Link removeUnderline onClick={ this.resend }>resend</Link> the email.</p>
      </TextContainer>
    )
  }

  render() {
    const toastMarkup = this.state.toast.active ? (
      <Toast content={ this.state.toast.content } error={ this.state.toast.error } onDismiss={ this.dismissToast }/>
    ) : null

    const cardTitle = !this.state.isEmailSent
      ? 'Howdy, enter your account email address to recover password'
      : 'Howdy, please reset your password'

    const pageContent = !this.state.isEmailSent
      ? this.renderResetPasswordForm()
      : this.renderEmailSent()


    return (
      <LoginCard summary={ cardTitle }>
        <>
          { pageContent }
          { toastMarkup }
        </>
      </LoginCard>
    )
  }
}
