import classNames from 'classnames'
import React from 'react'
import styles from './BlockMaskLayer.module.scss'


export default function BlockMaskLayer(props) {
  const _classNames = props.className ?? []

  const sectionMarkup = props.show ? (<>
    <div
      style={ props.style } className={ classNames([
      styles.Mask__Container,
      ..._classNames,
    ]) }
    >
      { props.maskNode }
    </div>
    { props.children }
  </>) : (props.children)

  return (
    <div style={ { position: 'relative' } }>
      { sectionMarkup }
    </div>
  )
}
