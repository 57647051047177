import React, { Component } from 'react'
import { Button, Form, FormLayout, Link, TextField, Toast } from '@shopify/polaris'
import './Login.scss'
import { reqLogin, reqLoginByToken } from '../../../api/index'
import memory from '../../../utils/memory'
import storage from '../../../utils/storage'
import { LoginCard, PasswordField } from '../../../components'
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

// 登陆的路由组件
class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tokenLoggingIn: false,

      loading: false,
      email: '',
      password: '',

      emailError: false,
      pwdError: false,
      viewPlaintextPassword: false,

      toast: {
        active: false,
        content: '',
        error: false,
      },
    }
  }

  showToast(content, error = false) {
    this.setState({
      toast: {
        active: true,
        content,
        error,
      },
    })
  }

  dismissToast = () => {
    this.setState({
      toast: {
        active: false,
      },
    })
  }

  handleSubmit = (event) => {

    let isPass = 0
    if (this.state.email === '') {
      isPass = 1
      this.setState({ emailError: 'Account email can\'t be blank' })
    }

    if (this.state.password === '') {
      isPass = 1
      this.setState({ pwdError: 'Password can\'t be blank' })
    }
    if (isPass) {
      return false
    }

    this.setState({ loading: true })

    this.login(this.state.email, this.state.password)
  }


  componentDidMount() {
    const { search } = this.props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)

    const id = searchParams.get('id')
    const t = searchParams.get('t')
    const sig = searchParams.get('sig')

    const msg = searchParams.get('msg')
    const msgType = searchParams.get('msg-type')

    // memory.user_info = {}
    // memory.setting = {}

    if (msg) {
      this.showToast(msg, '1' !== msgType)
    }

    // if ('Verified successfully' === msg && false === memory.user_info?.is_email_verified) {
    //   this.props.onLogin({
    //     ...memory.user_info,
    //     is_email_verified: true,
    //   })
    // }

    // if (false === memory.user_info?.is_email_verified) {
    //   this.jumpToSignUpVerifyEmail()
    // }

    if (sig && id && t) {
      this.setState({ tokenLoggingIn: true })
      this.loginByToken(sig, id, t)
    }
  }

  // 利用账号密码登录
  login(email, password) {
    reqLogin(email, Base64.stringify(Utf8.parse(password))).then(
      resp => {
        // 保存用户数据和全局配置文件到内存
        const setting = resp.data.data.setting
        memory.setting = setting
        storage.saveInfo('setting', setting)

        const user_info = resp.data.data.user_info
        this.props.onLogin(user_info)

        if (!user_info.is_email_verified) {
          this.jumpToVerifySignUpEmail()
        } else {
          this.props.history.replace('/')
        }
      },
    ).catch(
      err => {
        this.showToast(err.response.data.msg)
        this.setState({ loading: false })
      },
    )
  }

  // 来自后台的登录
  loginByToken(sig, id, t) {
    reqLoginByToken({ sig, id, t }).then(
      resp => {
        // 保存用户数据和全局配置文件到内存
        const user_info = resp.data.data.user_info
        const setting = resp.data.data.setting

        memory.setting = setting

        storage.saveInfo('setting', setting)

        // 设置邮箱已验证；管理员登录的就不用验证邮箱了
        user_info.is_email_verified = true
        user_info.is_from_management = true

        this.props.onLogin(user_info)

        this.props.history.replace('/')
      },
    )
  }

  jumpToVerifySignUpEmail() {
    this.props.history.push('/signup', { verifyEmail: true })
  }

  render() {
    if (this.state.tokenLoggingIn) {
      return null
    }

    // if (!!memory.user_info?.token) {
    //   return <Redirect to="/"/>
    // }

    const toastMarkup = this.state.toast.active ? (
      <Toast content={ this.state.toast.content } error={ this.state.toast.error } onDismiss={ this.dismissToast }/>
    ) : null

    return (
      <LoginCard
        summary="Howdy, log in to Affiliate.ParcelPanel.com with your account"
      >
        <>
          <div className="login-form-wrap">
            <Form onSubmit={ this.handleSubmit }>
              <FormLayout>
                <TextField
                  id="email"
                  value={ this.state.email }
                  label="Account email"
                  type="email"
                  onChange={ val => this.setState({
                    email: val,
                    emailError: '',
                  }) }
                  error={ this.state.emailError }
                  autoComplete="username"
                />

                <PasswordField
                  id="password"
                  value={ this.state.password }
                  label="Password"
                  type="password"
                  onChange={ val => this.setState({
                    password: val,
                    pwdError: '',
                  }) }
                  error={ this.state.pwdError }
                  autoComplete="current-password"
                  viewPlaintext={ this.state.viewPlaintextPassword }
                  onViewPlaintextClick={ this.handleViewPlaintextPasswordClick }
                />

                <div className="forgetPassword-wrap">
                  <Link
                    removeUnderline
                    onClick={ () => this.props.history.replace('/forgot-password') }
                  >
                    Forgot password?
                  </Link>
                </div>

                <Button loading={ this.state.loading } primary submit fullWidth>Login</Button>
              </FormLayout>
            </Form>
          </div>

          <div className="footerAction-wrap">
            <Link
              removeUnderline
              onClick={ () => this.props.history.replace('/signup') }
            >
              Not on Affiliate.ParcelPanel.com? Create an account
            </Link>
          </div>

          { toastMarkup }
        </>
      </LoginCard>
    )
  }


  handleViewPlaintextPasswordClick = (viewPlaintextPassword) => {
    this.setState({ viewPlaintextPassword })
  }
}

export { Login }