import { Badge, IndexTable, EmptySearchResult } from '@shopify/polaris'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import styles from './ReferralsTable.module.scss'
import dayjs from 'dayjs'
import { NormalLink } from '../../../../components'


export function ReferralsTable({
  items,
  loading,
}) {

  const columnConfig = {
    shop_url: {
      headingTitle: 'Store URL',
    },
    is_uninstalled: {
      headingTitle: 'Status',
      render: (value) => value
        ? <Badge status="warning">Uninstalled</Badge>
        : <Badge status="success">Installed</Badge>,
    },
    parcelpanel_plan: { headingTitle: 'ParcelPanel plan' },
    shopify_plan: { headingTitle: 'Shopify plan' },
    created_at: {
      headingTitle: 'Install date',
      render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
    },
    referral_link: {
      headingTitle: 'Referral link',
      render: (value) => {
        const url = value ? `https://${ value }` : ''
        return <NormalLink url={ url }>{ value }</NormalLink>
      },
    },
  }

  const headings = useMemo(
    () => (Object.values(columnConfig).map(config => ({ title: config.headingTitle }))),
    [],
  )

  const rowMarkup = items.map(renderRow)

  const emptyStateMarkup = <CustomEmptyState headings={ headings }/>

  const itemCount = useMemo(() => items.length, [ items ])

  return (
    <div className={ styles.Table }>
      <IndexTable
        headings={ headings }
        itemCount={ itemCount }
        selectable={ false }
        loading={ loading }
        emptyState={ emptyStateMarkup }
        resourceName={ { singular: 'referral', plural: 'referrals' } }
      >
        { rowMarkup }
      </IndexTable>
    </div>
  )

  function renderRow(x, index) {
    return (
      <IndexTable.Row
        id={ `${ (x.shop_url) }` }
        key={ x.shop_url }
        position={ index }
      >
        {
          Object.entries(columnConfig).map((
            [
              field,
              {
                className,
                id,
                render,
              },
            ],
          ) => {
            const colId = id || field

            let content
            if (render) {
              // @ts-ignore
              content = render(x[field], x)
            } else {
              // @ts-ignore
              content = String(x[field])
            }

            return (
              <IndexTable.Cell key={ colId }>
                <div
                  data-id={ colId }
                  className={ classNames(styles.column, className) }
                >
                  { content }
                </div>
              </IndexTable.Cell>
            )
          })
        }
      </IndexTable.Row>
    )
  }
}

function CustomEmptyState({
  headings,
}) {
  return (
    <div className={ styles.CustomEmptyState__Wrapper }>
      {
        headings && (
          <table className="Polaris-IndexTable__Table Polaris-IndexTable__Table--unselectable">
            <thead>
            <tr>
              {
                headings.map(({ title }) => <th
                  key={ title }
                  className="Polaris-IndexTable__TableHeading"
                >{ title }</th>)
              }
            </tr>
            </thead>
            <tbody>
            <tr className={classNames("Polaris-IndexTable__TableRow",styles.TeferralsMyTable)}>
              {
                headings.map(({ title }) => <td key={ title }/>)
              }
            </tr>
            </tbody>
          </table>
        )
      }
      <div className={ styles.EmptySearchState__Wrapper }>
        <EmptySearchResult
          title="No orders yet"
          description="Try changing the filters or search term"
          withIllustration
        />
      </div>
    </div>
  )
}
