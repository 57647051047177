// localstorage
export default {

  saveInfo(key, info) {
    window.localStorage.setItem(key, JSON.stringify(info))
  },

  getInfo(key) {
    return JSON.parse(window.localStorage.getItem(key)) || '{}'
  },

  removeInfo(key) {
    window.localStorage.removeItem(key)
  },
}