import React, { Component } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import memory from './utils/memory'
import { ForgotPassword, Login, ResetPassword, SignUp } from './pages/user'
import { Account, Admin, Home, Payouts, Referrals } from './pages'
import storage from './utils/storage'
import intercom from './utils/intercom'

const INTERCOM_APP_ID = 't6tndyrj'

class App extends Component {
  isBootIntercom = false

  constructor(props) {
    super(props)
    this.state = {
      isLogin: !!memory.user_info?.token,
      isLoginFromManagement: memory.user_info?.is_from_management || false,
      isEmailVerified: memory.user_info?.is_email_verified || false,
    }
  }

  componentDidMount() {
    intercom.initialize(INTERCOM_APP_ID)
    this.initIntercom()
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.location !== this.props.location) {
      if (this.isBootIntercom) {
        window.Intercom('update')
      } else {
        this.initIntercom()
      }
    }
  }

  initIntercom() {
    if (this.isBootIntercom) {
      return
    }

    // 登录时不继续做初始化
    // /login 包含了后台登录处理（经常切换用户，维护 intercom 信息状态会比较复杂）
    // 但每次 componentWillReceiveProps() 都会初始化一下 intercom
    if ('/login' === this.props.location.pathname) {
      return
    }

    if (this.isLogin() && !this.isLoginFromManagement()) {
      const userInfo = memory.user_info

      const intercomSettings = {
        app_id: INTERCOM_APP_ID,
        name: `${ userInfo.first_name } ${ userInfo.last_name }` || 'no name',
        email: userInfo.email || 'no email',
        user_id: `af_${ userInfo.id }` || 'no userId',
        shop_name: userInfo.partner_type_text || 'no shopName',
        from_app: 'ParcelPanel Affiliate',
      }

      window.Intercom('boot', intercomSettings)
      this.isBootIntercom = true
    }
  }

  shutIntercom() {
    window.Intercom('shutdown')
    this.isBootIntercom = false
  }

  setUserInfo = (userInfo) => {
    memory.user_info = userInfo

    storage.saveInfo('user_info', userInfo)

    this.setState({
      isLogin: !!userInfo?.token,
      isLoginFromManagement: userInfo?.is_from_management || false,
      isEmailVerified: userInfo?.is_email_verified || false,
    }, () => {
      this.shutIntercom()
    })
  }

  removeUserInfo = () => {
    memory.user_info = {}

    storage.removeInfo('user_info')

    this.setState({
      isLogin: false,
      isEmailVerified: false,
    }, () => {
      this.shutIntercom()
    })
  }

  isLoginFromManagement() {
    return this.state.isLoginFromManagement
  }

  isLogin() {
    return this.state.isLogin
  }

  isEmailVerified() {
    return this.state.isEmailVerified
  }

  render() {
    return (
      <Switch>
        {/*{*/ }
        {/*  mainRouters.map(*/ }
        {/*    v => (*/ }
        {/*      <Route key={ v.path } { ...v }/>*/ }
        {/*    ),*/ }
        {/*  )*/ }
        {/*}*/ }
        <Route path="/login" render={ props => <Login onLogin={ this.setUserInfo } { ...props }/> }/>
        <Route path="/signup" render={ props => <SignUp onLogin={ this.setUserInfo } { ...props }/> }/>
        <Route path="/forgot-password" component={ ForgotPassword }/>
        <Route path="/reset-password" render={ props => <ResetPassword onLogin={ this.setUserInfo } { ...props }/> }/>
        {/*<Route path="/" component={ Admin }/>*/ }
        {/*<Route path="/">*/ }
        {/*  <Admin></Admin>*/ }
        {/*</Route>*/ }
        {/*<Route exact path="/" component={ Home }/>*/ }

        {/* 会重复渲染父组件 Admin 导致一直生成 Frame */ }
        {/* Key 如果相同的话就不会出现上面的情况，但是 / 还是会出现渲染父组件 */ }
        {/*{*/ }
        {/*  this.isLogin() && adminRouters.map(*/ }
        {/*    ({ component: Component, path }) => (*/ }
        {/*      <Route*/ }
        {/*        key=""*/ }
        {/*        path={ path }*/ }
        {/*        render={ props => <Admin { ...props }><Component { ...props }/></Admin> }*/ }
        {/*      />*/ }
        {/*    ),*/ }
        {/*  )*/ }
        {/*}*/ }

        {/* 只能渲染出第一条路由 */ }
        {/*<Admin><Route path="/accounts" component={ Account }/></Admin>*/ }
        {/*<Admin><Route path="/" component={ Home }/></Admin>*/ }

        {/* 使用 /home 别名导致导航组件无法匹配到当前选项 */ }
        {/*{ this.isLogin() && <Route path="/home" render={ props => <Admin { ...props }><Home { ...props }/></Admin> }/> }*/ }
        { this.isLogin() && this.isEmailVerified() &&
        <Route path="/accounts" render={ props => <Admin onLogout={ this.removeUserInfo } { ...props }><Account onLogout={ this.removeUserInfo } onUserInfoUpdate={ this.setUserInfo } { ...props }/></Admin> }/> }
        { this.isLogin() && this.isEmailVerified() &&
        <Route path="/referrals" render={ props => <Admin onLogout={ this.removeUserInfo } { ...props }><Referrals { ...props }/></Admin> }/> }
        { this.isLogin() && this.isEmailVerified() &&
        <Route path="/payouts" render={ props => <Admin onLogout={ this.removeUserInfo } { ...props }><Payouts { ...props }/></Admin> }/> }
        <Route
          exact
          path="/"
          render={
            props => (
              !this.isLogin() || !this.isEmailVerified()
                ? <Login onLogin={ this.setUserInfo } { ...props }/>
                : <Admin onLogout={ this.removeUserInfo } { ...props }><Home { ...props }/></Admin>
            )
          }
        />

        <Redirect to="/"/>
      </Switch>
    )
  }
}

export default withRouter(App)