import React, { Component } from 'react'
import { FormLayout, TextField } from '@shopify/polaris'

export default class SignupStepThree extends Component {

  handleInputChange = (val, name) => {
    this.props.onChange(this, name, val)
  }

  render() {
    return (
      <FormLayout>
        <TextField
          id="paypal_address"
          type="email" label="PayPal email"
          value={ this.props.info.paypal_address }
          onChange={ this.handleInputChange }
          error={ this.props.info.paypal_address_error }
          autoComplete="off"
        />
      </FormLayout>
    )
  }
}
