import React, { Component } from 'react'
import { Form, FormLayout, TextField } from '@shopify/polaris'
import { PasswordField } from '../../../../components'

export default class SignupStepOne extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewPlaintextPassword: false,
    }
  }

  handleInputChange = (val, name) => {
    this.props.onChange(this, name, val)
  }

  render() {
    return (
      <Form>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              id="firstname"
              type="text" label="First name"
              value={ this.props.info.firstname }
              onChange={ this.handleInputChange }
              error={ this.props.info.firstname_error }
              autoComplete="given-name"
            />
            <TextField
              id="lastname"
              type="text" label="Last name"
              value={ this.props.info.lastname }
              onChange={ this.handleInputChange }
              error={ this.props.info.lastname_error }
              autoComplete="family-name"
            />
          </FormLayout.Group>

          <TextField
            id="email"
            type="email" label="Account email"
            value={ this.props.info.email }
            onChange={ this.handleInputChange }
            error={ this.props.info.email_error }
            autoComplete="username"
          />
          <PasswordField
            id="password"
            type="password" label="Password"
            value={ this.props.info.password }
            onChange={ this.handleInputChange }
            error={ this.props.info.password_error }
            autoComplete="new-password"
            viewPlaintext={ this.state.viewPlaintextPassword }
            onViewPlaintextClick={ this.handleViewPlaintextPasswordClick }
          />
          <PasswordField
            id="confirmpwd"
            type="password" label="Confirm password"
            value={ this.props.info.confirmpwd }
            onChange={ this.handleInputChange }
            error={ this.props.info.confirmpwd_error }
            autoComplete="new-password"
            viewPlaintext={ this.state.viewPlaintextPassword }
            onViewPlaintextClick={ this.handleViewPlaintextPasswordClick }
          />
        </FormLayout>
      </Form>
    )
  }


  handleViewPlaintextPasswordClick = (viewPlaintextPassword) => {
    this.setState({ viewPlaintextPassword })
  }
}
