import React, { Component } from 'react'
import { Button, Link, TextContainer, Toast } from '@shopify/polaris'
import { ChevronLeftMinor } from '@shopify/polaris-icons'
import './SignUp.scss'
import SignupStepOne from './components/step_one'
import SignupStepTwo from './components/step_two'
import SignupStepThree from './components/step_three'
import { reqPartnerType, reqRegister, reqResendRegisterEmail } from '../../../api/index'
import { LoginCard } from '../../../components'
import memory from '../../../utils/memory'
import storage from '../../../utils/storage'

export class SignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // 这里做了一个状态提升，子组件共享了父亲组件的state
      name: '',
      email: '',
      password: '',
      partner_type: '',
      partner_plan_of_promote: '',
      promotion_plan: '',
      paypal_address: '',
      partner_type_option: [],
      current_step: 0,
      loading: false,

      error: '',

      firstname: '',
      lastname: '',
      confirmpwd: '',

      firstname_error: false,
      lastname_error: false,
      confirmpwd_error: false,

      name_error: false,
      email_error: false,
      password_error: false,
      partner_type_error: false,
      promotion_plan_error: false,
      partner_plan_of_promote_error: false,
      paypal_address_error: false,

      toast: {
        active: false,
        content: '',
        error: false,
      },
    }
  }

  componentDidMount() {
    if (this.props.history.location.state?.verifyEmail) {
      this.setState({
        current_step: 3,
      })
      this.resend()
      return
    }

    reqPartnerType().then(
      resp => {
        this.setState({
          partner_type_option: Object.values(resp.data.data),
        })
      },
    ).catch(
      error => {
        console.log(error)
        // this.setState({error})
      },
    )
  }

  showToast(content, error = false) {
    this.setState({
      toast: {
        active: true,
        content,
        error,
      },
    })
  }

  dismissToast = () => {
    this.setState({
      toast: {
        active: false,
      },
    })
  }

  resend = () => {
    reqResendRegisterEmail().then(
      resp => {
        if (204 === resp.status) {
          this.props.onLogin({
            ...memory.user_info,
            is_email_verified: true,
          })

          this.props.history.replace('/')

          return
        }

        this.showToast('Email sent')

        this.setState({
          loading: false,
        })
      },
    ).catch(
      err => {
        this.showToast(err.response?.data?.msg || 'Unknown error', true)
        this.setState({ loading: false })
      },
    )
  }

  validate_step1 = () => {
    let success = true

    if (this.state.firstname === '') {
      success = false
      this.setState({ firstname_error: 'First name can\'t be blank' })
    }

    if (this.state.lastname === '') {
      success = false
      this.setState({ lastname_error: 'Last name can\'t be blank' })
    }

    if (this.state.email === '') {
      success = false
      this.setState({ email_error: 'Email can\'t be blank' })
    }

    if (this.state.email !== '' && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.state.email))) {
      success = false
      this.setState({ email_error: 'Email is invalid' })
    }
    const pattern1 = /(?=.*[A-Z])/g;
    const pattern2 = /(?=.*[a-z])/g;
    const pattern3 = /(?=.*[0-9])/g;

    if (this.state.password === '') {
      success = false
      this.setState({ password_error: 'Password can\'t be blank' })
    } else if (this.state.password.length < 8) {
      success = false
      this.setState({ password_error: 'Password is too short (minimum is 8 characters)' })
    } else if (!pattern1.test(this.state.password) || !pattern2.test(this.state.password) || !pattern3.test(this.state.password)) {
      success = false
      this.setState({password_error: 'Password have to use 8 more characters with a mix of uppercase and lowercase letters & numbers.'})
    } else if (this.state.password !== this.state.confirmpwd) {
      success = false
      this.setState({ confirmpwd_error: 'The password entered didn\'t match, please try again' })
    }



    return success
  }

  validate_step2 = () => {
    let success = true

    if (this.state.partner_type === '') {
      success = false
      this.setState({ partner_type_error: 'Partner type can\'t be blank' })
    }

    if (this.state.promotion_plan === '') {
      success = false
      this.setState({ promotion_plan_error: 'Promotion plan can\'t be blank' })
    }

    return success
  }

  validate_step3 = () => {
    let success = true

    if (this.state.paypal_address === '') {
      success = false
      this.setState({ paypal_address_error: 'PayPal email can\'t be blank' })
    }

    if (this.state.paypal_address !== '' && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.state.paypal_address))) {
      success = false
      this.setState({ paypal_address_error: 'Email is invalid' })
    }

    return success
  }

  signUp = () => {
    if (!this.validate_step3()) {
      return false
    }

    this.setState({ loading: true })

    let user_info = {
      email: this.state.email,
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      password: this.state.password,
      partner_type: this.state.partner_type,
      promotion_plan: this.state.promotion_plan,
      paypal_address: this.state.paypal_address,
    }

    reqRegister(user_info).then(
      resp => {
        // 保存用户数据和全局配置文件到内存
        const user_info = resp.data.data.user_info
        const setting = resp.data.data.setting

        memory.user_info = user_info
        memory.setting = setting

        storage.saveInfo('setting', setting)
        storage.saveInfo('user_info', user_info)

        this.nextStep()
        this.setState({ loading: false })
      },
    ).catch(
      err => {
        this.setState({ loading: false })

        if (err.response?.data?.msg) {
          this.showToast(err.response.data.msg, true)
        }
      },
    )
  }

  nextStep = () => {
    if (this.state.current_step < 3) {

      // 第1步验证
      if (this.state.current_step === 0 && !this.validate_step1()) {
        return false
      }

      // 第2步验证
      if (this.state.current_step === 1 && !this.validate_step2()) {
        return false
      }

      this.setState({ current_step: this.state.current_step + 1 })
    }
  }

  goBack = () => {
    if (0 === this.state.current_step) {
      this.props.history.replace('/login')
    } else {
      this.setState({ current_step: this.state.current_step - 1 })
    }
  }

  getChange = (result, name, val) => {
    this.setState({
      [name]: val,
      [`${ name }_error`]: false,      // 如果有输入，错误验证重置
    })
  }

  renderIdentityInfoForm = () => {
    return <SignupStepOne onChange={ this.getChange } info={ this.state }/>
  }

  renderBussInfoForm = () => {
    return <SignupStepTwo onChange={ this.getChange } info={ this.state }/>
  }

  renderPaymentInfoForm = () => {
    return <SignupStepThree onChange={ this.getChange } info={ this.state }/>
  }

  renderEmailSent = () => {
    return (
      <TextContainer spacing="tight">
        <p>An email with instructions to verify your account email address has been sent to you, please check your inbox and verify there.</p>
        <p>If you have not received, please check your spam, or <Link removeUnderline onClick={ this.resend }>resend</Link> the email.</p>
      </TextContainer>
    )
  }

  renderStepAction() {
    if (3 === this.state.current_step) {
      return null
    }

    let actionButtonMarkup

    if (2 === this.state.current_step) {
      actionButtonMarkup = <Button loading={ this.state.loading } onClick={ this.signUp } primary>Create account</Button>
    } else {
      actionButtonMarkup = <Button onClick={ this.nextStep } primary>Next</Button>
    }

    return (
      <div className="stepAction-wrap">
        <Button
          plain
          removeUnderline
          monochrome
          icon={ ChevronLeftMinor }
          onClick={ this.goBack }
        >
          Back
        </Button>
        { actionButtonMarkup }
      </div>
    )
  }

  render() {
    let step = this.state.current_step

    const toastMarkup = this.state.toast.active ? (
      <Toast content={ this.state.toast.content } error={ this.state.toast.error } onDismiss={ this.dismissToast }/>
    ) : null

    const steps = [
      {
        summary: 'Howdy, sign up for Affiliate.ParcelPanel.com with your account',
        render: this.renderIdentityInfoForm,
      },
      {
        summary: 'Tell us a little about yourself',
        render: this.renderBussInfoForm,
      },
      {
        summary: 'Add a PayPal so you can get paid',
        render: this.renderPaymentInfoForm,
      },
      {
        summary: 'Howdy, please verify your account',
        render: this.renderEmailSent,
      },
    ]

    return (
      <>
        <LoginCard summary={ steps[step].summary }>
          <>
            { steps[step].render() }
            { toastMarkup }
          </>
          { this.renderStepAction() }
        </LoginCard>
      </>
    )
  }
}
