import ajax from './ajax'

// 用户相关
export const reqLogin = (email, password) => ajax('login', { email, password }, 'POST')
export const reqLoginByToken = data => ajax('login/token', data, 'POST')
export const reqSave = data => ajax('save-user-info', data, 'POST')
export const reqAccountChangePassword = data => ajax('account/change-password', data, 'POST')
// export const reqUserDetail = () => ajax('details', {}, 'GET')
export const reqRegister = (user_info) => ajax('register', user_info, 'POST')
export const reqResendRegisterEmail = () => ajax('register/resend-email', {}, 'POST')
export const reqResetPassword = (email, token, password) => ajax('reset-password', { email, token, password }, 'POST')
export const reqVerifyResetPasswordToken = (email, token) => ajax('reset-password/verify-token', { email, token }, 'POST')
export const reqSendResetPasswordEmail = (email) => ajax('password/email', { email }, 'POST')

// 配置相关
export const reqPartnerType = () => ajax('partnerType', {}, 'GET')
export const reqAppList = () => ajax('appList', {}, 'GET')

// 转化相关
export const reqPaymentDetail = data => ajax('paymentDetail', data, 'POST')
export const reqPayoutsTotalInfo = data => ajax('payouts-total-info', data, 'POST')

export { default as homeApi } from './home'
export { default as referralsApi } from './referrals'

// 后面加 API 都放到各自页面的 js 上分类好
