export default {
  toThousands(num) {
    let _num = (num || 0).toString()
      , result = ''
    while (_num.length > 3) {
      result = ',' + _num.slice(-3) + result
      _num = _num.slice(0, _num.length - 3)
    }
    if (_num) {
      result = _num + result
    }
    return result
  },
  /**
   * 求百分比
   *
   * 6/3 = 2
   * 6:被除数
   * 3:除数
   *
   * @param dividend  被除数
   * @param divisor   除数
   * @param returnStr 如果其中一个数为 0 那么输出什么。
   */
  getPercentage(dividend, divisor, returnStr = '-') {
    // 如果不是数字
    if (typeof dividend !== 'number' || typeof divisor !== 'number') return returnStr

    // 如果都是 0
    if (dividend <= 0 || divisor <= 0) return returnStr

    return (Math.round(dividend / divisor * 10000) / 100.00) + '%'
  },

  /**
   * 没有 - 将转换为千分分割的数字
   * @param num
   */
  noDashConvertThousands(num) {
    if (num === '-') return num
    return this.toThousands(num)
  },
}
