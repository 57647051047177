import {
  Button,
  DatePicker,
  FormLayout,
  Popover,
  Select,
  Stack,
  TextField,
} from '@shopify/polaris'
import dayjs from 'dayjs'
import React from 'react'
import { CalendarTickMajor } from '@shopify/polaris-icons'
import styles from './TableDatePicker.module.scss'
import { checkDatetimeFormat } from '../../../../utils/datetime'


let hasCustomOption = false

// 默认选中的
export const defaultSelected = 'all'

export default class TableDatePicker extends React.Component {

  dateRange = [
    {
      label: 'Today',
      value: '1',
    },
    {
      label: 'Last 7 days',
      value: '7',
    },
    {
      label: 'Last 30 days',
      value: '30',
    },
    {
      label: 'Last 60 days',
      value: '60',
    },
    {
      label: 'Last 90 days',
      value: '90',
    },
    {
      label: 'All time',
      value: 'all',
    },
  ]


  constructor(props) {
    super(props)

    if ('all' === defaultSelected) {
      this.defaultVal = {
        startDate: dayjs(),
        endDate: dayjs(),
      }
    } else {
      this.defaultVal = {
        startDate: dayjs().subtract(defaultSelected - 1, 'day'),
        endDate: dayjs(),
      }
    }

    const selectedText = this.dateRange.find(v => defaultSelected === v.value).label

    this.state = {

      popoverActive: false,
      selectedText,
      selected: defaultSelected,

      // 开始时间选择过去30天前
      startText: this.defaultVal.startDate.format('YYYY-MM-DD').toString(),
      endText: this.defaultVal.endDate.format('YYYY-MM-DD').toString(),

      current: {
        month: this.defaultVal.startDate.month(),
        year: this.defaultVal.startDate.year(),
      },

      selectedDates: {
        start: this.defaultVal.startDate.toDate(),
        end: this.defaultVal.endDate.toDate(),
      },
      allowRange: true,
      date_commenced: this.defaultVal.startDate.format('YYYY-MM-DD').toString(),
      end_commenced: this.defaultVal.endDate.format('YYYY-MM-DD').toString(),
      onceNumber: 0,
      selectedButtonText: selectedText,
      value_commenced: 'custom',
      changeDate: true,
    }
  }


  render() {

    const activator = (
      <Button
        icon={ CalendarTickMajor }
        onClick={ this.togglePopoverActive }
      >
        { this.state.selectedButtonText }
      </Button>
    )

    return (
      <div className={ styles.Header }>
        <h1 className="page-title">Welcome to ParcelPanel Affiliate</h1>
        <Popover
          fluidContent
          preferredAlignment="center"
          active={ this.state.popoverActive }
          activator={ activator }
          onClose={ this.togglePopoverActive }
          ariaHaspopup={ false }
        >
          <div className={ styles.tableDate }>
            <Popover.Pane sectioned>
              <FormLayout>
                { this.dateRangeSelected() }
                { this.dateRangeTextField() }
                { this.datePicker() }
              </FormLayout>
            </Popover.Pane>
            <Popover.Pane sectioned>
              { this.dateButton() }
            </Popover.Pane>
          </div>
        </Popover>
      </div>
    )
  }

  // 设置是否显示
  setPopoverActive = (popoverActive) => {
    this.setState(() => ({ popoverActive }))

    // 记录历史时间
    if (popoverActive && this.state.onceNumber < 1) {
      this.setState((prevState) => ({
        value_commenced: prevState.selected,
        onceNumber: prevState.onceNumber + 1,
        date_commenced: prevState.startText,
        end_commenced: prevState.endText,
      }))
    }
  }

  togglePopoverActive = () => {
    this.setPopoverActive(!this.state.popoverActive)
    this.setState({ onceNumber: 0 })
  }

  /**
   * 下拉列表选择器
   */
  dateRangeSelected() {
    return (
      <Select
        label="Date range"
        options={ this.dateRange }
        value={ this.state.selected }
        onChange={ this.handleSelectChange }
      />
    )
  }

  /** 处理下拉选择器变化事件 */
  handleSelectChange = (selected) => {
    this.setPopoverActive(false)

    const selectedText = this.dateRange.find(v => selected === v.value).label

    let _selected = parseInt(selected) || 0

    if ('custom' !== selectedText && hasCustomOption) {
      hasCustomOption = false
      this.dateRange.pop()
    }

    let selectedDates

    if (_selected) {
      selectedDates = {
        start: dayjs().subtract(_selected - 1, 'day').toDate(),
        end: dayjs().toDate(),
      }
    } else {
      selectedDates = {
        start: dayjs().toDate(),
        end: dayjs().toDate(),
      }
    }


    this.setState({
      selectedDates,
      selected,
      selectedText,
      selectedButtonText: selectedText,
    }, () => {
      // eslint-disable-next-line no-unused-expressions
      this.props.onChange?.(
        selected,
        dayjs(selectedDates.start).startOf('d'),
        dayjs(selectedDates.end).endOf('d'),
      )

      this.dateChangeHandle(true)

      // stores.EventsStore.setSelected(this.state.selected)
      // stores.EventsStore.setStartTime(void 0)
      // stores.EventsStore.setEndTime(void 0)
    })
  }

  /**
   * 日期范围文本框
   */
  dateRangeTextField() {
    return (
      <Stack>
        <Stack.Item fill>
          <TextField
            label="Starting"
            minLength={ 10 }
            maxLength={ 10 }
            value={ this.state.startText }
            placeholder={ 'YYYY-MM-DD' }
            onChange={ (startText) => this.setState(() => ({ startText }), this.dateRangeTextHandle) }
            autoComplete="off"
          />
        </Stack.Item>
        <Stack.Item fill>
          <TextField
            label="Ending"
            minLength={ 10 }
            maxLength={ 10 }
            placeholder={ 'YYYY-MM-DD' }
            value={ this.state.endText }
            onChange={ (endText) => this.setState(() => ({ endText }), this.dateRangeTextHandle) }
            autoComplete="off"
          />
        </Stack.Item>
      </Stack>
    )
  }

  /**
   * 日期范围文本处理，如果变化了就同步到日历选择
   */
  dateRangeTextHandle() {
    let selectedDates = {
      start: this.state.selectedDates.start,
      end: this.state.selectedDates.end,
    }

    // let startText = null
    //   , endText = null

    let startDayjs = checkDatetimeFormat('YYYY-MM-DD', this.state.startText),
      endDayjs = checkDatetimeFormat('YYYY-MM-DD', this.state.endText)

    if (null !== startDayjs) {
      //   // 如果开始日期 小于 所规定的最小日期
      //   if (startDayjs.isBefore(disableDatesBefore)) {
      //     startText = disableDatesBefore.format('YYYY-MM-DD')
      //     startDayjs = disableDatesBefore
      //   }
      //
      selectedDates.start = startDayjs.toDate()
    }
    //
    if (null !== endDayjs) {
      //   // 如果结束日期 打于 所规定的最大日期
      //   if (endDayjs.isAfter(disableDatesAfter)) {
      //     endDayjs = disableDatesAfter
      //     endText = disableDatesAfter.format('YYYY-MM-DD')
      //   }
      //
      selectedDates.end = endDayjs.toDate()
    }

    let selectedText = `${ dayjs(selectedDates.start).format('MMM D, YYYY').toString() } - ${ dayjs(selectedDates.end).format('MMM D, YYYY').toString() }`

    // 处理文本框内容
    let otherStates = {}
    // if (startText) {
    //   otherStates = { startText }
    // }
    // if (endText) {
    //   otherStates = { ...otherStates, endText }
    // }

    // 处理 选中 最开始的年月
    if (startDayjs) {
      otherStates = {
        ...otherStates,
        current: {
          year: startDayjs.year(),
          month: startDayjs.month(),
        },
      }
    }
    if (!hasCustomOption) {
      hasCustomOption = true
      this.dateRange.push({
        label: 'Custom',
        value: 'custom',
      })
    }

    // 处理完成后，执行获取图表数据接口
    this.setState(() => ({
      selected: 'custom',
      selectedDates,
      selectedText,
      ...otherStates,
    }))
  }

  /**
   * 日期选择器
   */
  datePicker() {
    return (
      <DatePicker
        month={ this.state.current.month }
        year={ this.state.current.year }
        // disableDatesBefore={ disableDatesBefore.toDate() }
        // disableDatesAfter={ disableDatesAfter.toDate() }
        onMonthChange={
          (month, year) => this.setState(() => ({
            current: {
              month,
              year,
            },
          }))
        }
        selected={ this.state.selectedDates }
        onChange={ (selectedDates) => {
          let changeDate = false
          let selectedText = `${ dayjs(selectedDates.start).format('MMM D, YYYY').toString() } - ${ dayjs(selectedDates.end).format('MMM D, YYYY').toString() }`
          if (!hasCustomOption) {
            hasCustomOption = true
            this.dateRange.push({
              label: 'Custom',
              value: 'custom',
            })
          }
          this.setState(() => ({
            selectedDates,
            selected: 'custom',
            selectedText,
            changeDate,
          }), this.dateChangeHandle)
        } }
        multiMonth
        allowRange
      />
    )
  }


  /**
   * button按钮
   */
  dateButton() {
    const Cancel = () => {
      this.setState(state => ({
        startText: state.date_commenced,
        endText: state.end_commenced,
        selectedDates: {
          start: dayjs(state.date_commenced).toDate(),
          end: dayjs(this.state.end_commenced).toDate(),
        },
        selected: state.value_commenced,
        changeDate: true,
      }), () => {
        this.setPopoverActive(false)
      })
    }

    const Apply = () => {
      this.setPopoverActive(false)
      // 回调
      // eslint-disable-next-line no-unused-expressions
      this.props.onChange?.(
        'custom',
        dayjs(this.state.selectedDates.start).startOf('d'),
        dayjs(this.state.selectedDates.end).endOf('d'),
      )
      this.setState({
        selectedButtonText: this.state.selectedText,
        changeDate: true,
      })
      if (this.state.selected === 'custom') {
        // setTimeout(() => {
        //   // stores.EventsStore.setStartTime(this.state.startText)
        //   // stores.EventsStore.setEndTime(this.state.endText)
        //   // stores.EventsStore.setSelected(void 0)
        // }, 1)
      }
    }

    return (
      <Stack distribution="equalSpacing">
        <Button onClick={ Cancel }>Cancel</Button>
        <Button
          primary
          disabled={ this.state.date_commenced === this.state.startText && this.state.end_commenced === this.state.endText && this.state.changeDate }
          onClick={ Apply }
        >
          Apply
        </Button>
      </Stack>
    )
  }

  /**
   * 日期改变处理事件
   * @private
   */
  dateChangeHandle(refreshC = false) {
    this.setSelectedTexts()
    if (refreshC) {
      this.setCurrentMonthYear()
    }
    console.log('日期选择')
  }

  /**
   * 设置选中的文本信息
   * @private
   */
  setSelectedTexts() {
    this.setState(() => ({
      startText: dayjs(this.state.selectedDates.start).format('YYYY-MM-DD').toString(),
      endText: dayjs(this.state.selectedDates.end).format('YYYY-MM-DD').toString(),
    }))
  }

  /**
   * 设置当前选择的年月
   * @private
   */
  setCurrentMonthYear() {
    let startDayjs = dayjs(this.state.selectedDates.start)
    let current = {
      month: startDayjs.month(),
      year: startDayjs.year(),
    }
    this.setState(() => ({ current }))
  }
}