import { SkeletonBodyText, TextContainer } from "@shopify/polaris"
import classNames from "classnames";
import React from "react";

export default function CasingPly(props) {
  const sectionMarkup = props.show ? (<>
    <TextContainer spacing="tight">
      <SkeletonBodyText lines={4}/>
    </TextContainer>
  </>) : (props.children)

  return (
    <div className={classNames([ 'relative' ])}>
      {sectionMarkup}
    </div>
  )
}
