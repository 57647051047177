import React, { Component } from 'react'
import {
  Card,
  ChoiceList,
  Filters,
  Pagination,
  Select,
  Tabs,
} from '@shopify/polaris'
import { ReferralsTable } from './components'
import styles from './Referrals.module.scss'
import { referralsApi } from '../../api'
import { DateChoiceList } from './components/DateChoiceList/DateChoiceList'
import dayjs from 'dayjs'
import _ from 'lodash'
import qs from 'qs'
import classNames from 'classnames'


export class Referrals extends Component {

  dateChoices = [
    { label: 'Today', value: '1' },
    { label: 'Last 7 days', value: '7' },
    { label: 'Last 30 days', value: '30' },
    { label: 'Last 60 days', value: '60' },
    { label: 'Last 90 days', value: '90' },
    { label: 'Custom', value: 'Custom' },
  ]

  tabs = [
    {
      id: 'all',
      content: 'All',
      total: void 0,
      filters: {},
    },
    {
      id: 'installed',
      content: 'Installed',
      total: void 0,
      filters: {
        status: [ 'installed' ],
      },
    },
    {
      id: 'uninstalled',
      content: 'Uninstalled',
      total: void 0,
      filters: {
        status: [ 'uninstalled' ],
      },
    },
    {
      id: 'paid',
      content: 'Paid',
      total: void 0,
      filters: {
        parcelpanel_plan: [ 'paid' ],
      },
    },
    {
      id: 'unpaid',
      content: 'Unpaid',
      total: void 0,
      filters: {
        parcelpanel_plan: [ 'unpaid' ],
      },
    },
  ]

  statusChoices = [
    { label: 'Installed', value: 'installed' },
    { label: 'Uninstalled', value: 'uninstalled' },
  ]

  parcelpanelPlanChoices = [
    { label: 'Paid', value: 'paid' },
    { label: 'Unpaid', value: 'unpaid' },
  ]

  sortOptions = [
    {
      value: 'desc',
      label: 'Date (newest first)',
    },
    {
      value: 'asc',
      label: 'Date (oldest first)',
    },
  ]

  state = {
    tabSelected: 0,

    queryValue: '',

    installDateSelected: void 0,  // ?string
    installDateStartingSelected: void 0,  // Dayjs
    installDateEndingSelected: void 0,  // Dayjs
    statusSelected: [],
    parcelpanelPlanSelected: [],
    shopifyPlanChoices: [],
    shopifyPlanSelected: [],
    referralLinkChoices: [],
    referralLinkSelected: [],

    sortBySelected: '',

    tableLoading: true,
    tableData: {
      data: [],
      page: {
        has_next: false,
        has_previous: false,
        last_page: 0,
        total: 0,
      },
    },

    tablePageNum: 1,

    pagination: {
      prevPage: void 0,
      nextPage: void 0,
    },
  }

  constructor(props) {
    super(props)

    const nowDayjs = dayjs()

    this.state.installDateStartingSelected = nowDayjs
    this.state.installDateEndingSelected = nowDayjs

    this.updateQueryParams = _.debounce(this.updateQueryParams, 150)
  }


  componentDidMount() {
    this.initFiltersOptions()
    this.parsePathSearch()
  }

  initFiltersOptions() {
    referralsApi.getFiltersOptions()
      .then(({ data: { data } }) => {
        console.log(data)

        this.setTabs(data.tabs)
        this.tabs.forEach((value) => {
          value.total = data.tabs.find(v => v.id === value.id)?.total || 0
        })

        const shopifyPlanChoices = data.shopify_plan_options
        const referralLinkChoices = data.referral_links.map(v => ({
          label: v,
          value: v,
        }))

        this.setState({
          shopifyPlanChoices,
          referralLinkChoices,
        })
      })
  }

  parsePathSearch() {
    // 在这里加 comma 选项好像无效，因为 parse() 解析 逗号的逻辑 比 解码search 的逻辑先执行，导致解析不出逗号(如果URL逗号进行了编码)
    const _queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    })

    const newState = {}
      , queryParams = {}

    if (_queryParams.installed_at) {
      const value = _queryParams.installed_at
      if (this.dateChoices.some(({ value: _val }) => _val === value)) {
        newState.installDateSelected = value
        queryParams.installed_at = value
      }
    } else if (_queryParams.installed_at_min && _queryParams.installed_at_max) {
      const installDateStartingSelected = dayjs(_queryParams.installed_at_min)
      const installDateEndingSelected = dayjs(_queryParams.installed_at_max)

      if (installDateStartingSelected.isValid() && installDateEndingSelected.isValid()) {

        newState.installDateSelected = 'Custom'
        newState.installDateStartingSelected = installDateStartingSelected
        newState.installDateEndingSelected = installDateEndingSelected

        queryParams.installed_at_min = installDateStartingSelected
        queryParams.installed_at_max = installDateEndingSelected
      }
    }

    this.setState(newState, () => {
      this.fetchTableData(this.getQueryParams())
    })
  }

  fetchTableData = (param) => {
    this.setState({ tableLoading: true })

    const nowPage = 1 < param.page ? param.page : 1
    const prevPage = nowPage - 1
    const nextPage = nowPage + 1

    referralsApi.getReferrals(param)
      .then(({ data: { data: tableData } }) => {
        const pagination = {
          prevPage: tableData.page.has_previous ? prevPage : void 0,
          nextPage: tableData.page.has_next ? nextPage : void 0,
        }
        this.setState({
          tableData,
          tableLoading: false,
          pagination,
        })
      })
  }

  headerTitle() {
    const totalItemsCount = this.state.tableData.page.total

    const resource = 1 === totalItemsCount
      ? 'referral'
      : 'referrals'

    return `Showing ${ totalItemsCount } ${ resource }`
  }

  render() {
    const {
      tabSelected,
      queryValue,
      sortBySelected,
      tableLoading,
      tableData: { data: tableItems },
      pagination: { prevPage, nextPage },
    } = this.state

    const tabs = this.getTabs()

    const headerTitleMarkup = (
      <div className={ styles.HeaderTitleWrapper }>{ this.headerTitle() }</div>
    )

    const filters = this.getFilters()
    const appliedFilters = this.getAppliedFilters()

    return (
      <div id="Page-Referrals" className="page-warp page--fullWidth">
        <div className="page-title">Referrals</div>
        <Card>
          <Tabs
            selected={ tabSelected }
            tabs={ tabs }
            onSelect={ this.handleTabsSelect }
          />

          <div className={ styles.FiltersWrapper }>
            <Filters
              queryPlaceholder="Filter store URL"
              filters={ filters }
              appliedFilters={ appliedFilters }
              queryValue={ queryValue }
              onClearAll={ this.handleFiltersClearAll }
              onQueryChange={ this.handleQueryChange }
              onQueryClear={ () => this.handleQueryChange('') }
            />
          </div>

          <div className={ styles.HeaderOuterWrapper }>
            <div className={ styles.HeaderWrapper }>
              <div className={ styles.HeaderContentWrapper }>
                { headerTitleMarkup }
                <div className={ styles.SortWrapper }>
                  <Select
                    label="Sort by"
                    labelInline
                    options={ this.sortOptions }
                    onChange={ this.handleSortByChange }
                    value={ sortBySelected }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={ styles.ReferralsTable }>
            <ReferralsTable
              items={ tableItems }
              loading={ tableLoading }
            />
          </div>


          {
            prevPage || nextPage ? (
              <div className={ styles.Pagination__Wrapper }>
                <Pagination
                  hasPrevious={ !!prevPage }
                  hasNext={ !!nextPage }
                  onNext={ () => this.handleTablePaginate(nextPage) }
                  onPrevious={ () => this.handleTablePaginate(prevPage) }
                />
              </div>
            ) : null
          }
        </Card>
      </div>
    )
  }

  getFilters() {
    const {
      installDateSelected,
      installDateStartingSelected,
      installDateEndingSelected,
      statusSelected,
      parcelpanelPlanSelected,
      shopifyPlanSelected,
      shopifyPlanChoices,
      referralLinkSelected,
      referralLinkChoices,
    } = this.state

    return [
      {
        key: 'installDate',
        label: 'Install date',
        filter: (
          <DateChoiceList
            choices={ this.dateChoices }
            selected={ installDateSelected }
            startingSelected={ installDateStartingSelected }
            endingSelected={ installDateEndingSelected }
            onChoiceListChange={ this.handleInstallDateChoiceListChange }
            onStartingChange={ this.handleStartingChange }
            onEndingChange={ this.handleEndingChange }
          />
        ),
      },
      {
        key: 'status',
        label: 'Status',
        filter: (
          <ChoiceList
            title="Status"
            titleHidden
            choices={ this.statusChoices }
            selected={ statusSelected }
            onChange={ this.handleStatusChange }
            // allowMultiple
          />
        ),
        shortcut: true,
      },
      {
        key: 'parcelpanelPlan',
        label: 'ParcelPanel plan',
        filter: (
          <ChoiceList
            title="ParcelPanel plan"
            titleHidden
            choices={ this.parcelpanelPlanChoices }
            selected={ parcelpanelPlanSelected }
            onChange={ this.handleParcelpanelPlanChange }
            // allowMultiple
          />
        ),
        shortcut: true,
      },
      {
        key: 'shopifyPlan',
        label: 'Shopify plan',
        filter: (
          <ChoiceList
            title="Shopify plan"
            titleHidden
            choices={ shopifyPlanChoices }
            selected={ shopifyPlanSelected }
            onChange={ this.handleShopifyPlanChange }
            allowMultiple
          />
        ),
        shortcut: true,
      },
      {
        key: 'referralLink',
        label: 'Referral link',
        filter: (
          <ChoiceList
            title="Referral link"
            titleHidden
            choices={ referralLinkChoices }
            selected={ referralLinkSelected }
            onChange={ this.handleReferralLinkChange }
            allowMultiple
          />
        ),
      },
    ]
  }

  getAppliedFilters() {
    const {
      installDateSelected,
      installDateStartingSelected,
      installDateEndingSelected,
      statusSelected,
      parcelpanelPlanSelected,
      shopifyPlanSelected,
      referralLinkSelected,
    } = this.state


    const appliedFilters = []
    if (!_.isEmpty(installDateSelected)) {
      const key = 'installDate'
      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, {
          selected: installDateSelected,
          starting: installDateStartingSelected,
          ending: installDateEndingSelected,
        }),
        onRemove: this.handleInstallDateChoiceListRemove,
      })
    }
    if (!_.isEmpty(statusSelected)) {
      const key = 'status'
      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, statusSelected),
        onRemove: this.handleStatusRemove,
      })
    }
    if (!_.isEmpty(parcelpanelPlanSelected)) {
      const key = 'parcelpanelPlan'
      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, parcelpanelPlanSelected),
        onRemove: this.handleParcelpanelPlanRemove,
      })
    }
    if (!_.isEmpty(shopifyPlanSelected)) {
      const key = 'shopifyPlan'
      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, shopifyPlanSelected),
        onRemove: this.handleShopifyPlanRemove,
      })
    }
    if (!_.isEmpty(referralLinkSelected)) {
      const key = 'referralLink'
      appliedFilters.push({
        key,
        label: this.disambiguateLabel(key, referralLinkSelected),
        onRemove: this.handleReferralLinkRemove,
      })
    }

    return appliedFilters
  }

  disambiguateLabel(key, value) {
    const mapper = {
      status: this.statusChoices,
      parcelpanelPlan: this.parcelpanelPlanChoices,
      shopifyPlan: this.state.shopifyPlanChoices,
      referralLink: this.state.referralLinkChoices,
    }

    switch (key) {
      case 'status':
      case 'parcelpanelPlan':
      case 'shopifyPlan':
      case 'referralLink':
        return value.map((v) => mapper[key].find(({ value }) => value === v)?.label).join(', ')
      case 'installDate':
        if ('Custom' === value.selected) {
          return `${ value.starting.format('MMM D, YYYY').toString() } - ${ value.ending.format('MMM D, YYYY').toString() }`
        }
        return this.dateChoices.find(({ value: _val }) => _val === value.selected)?.label
      default:
        return value
    }
  }


  /** Tabs 选项切换 */
  handleTabsSelect = (tabSelected) => {
    this.setState({ tabSelected })
    this.tabChangeFresh(tabSelected)
  }

  /** 针对 Tab 获取对应筛选参数 */
  tabChangeFresh(num) {
    // 获取一级导航对应的条件写入 URL
    // const tab = this.state.tabs[num] || this.state.tabs[0]
    const tab = this.tabs[num] || this.tabs[0]

    const tabId = tab.id
    const filters = tab.filters || {}

    // // 判断是否选择自定义导航
    // if (_.startsWith(tabId, 'CustomNav-')) {
    //   this.setState({ navSaved: true, navTitle: tab.content })
    // } else {
    //   this.setState({ navSaved: false, navTitle: '' })
    // }

    const installedAtParams = {
      installDateSelected: void 0,
      installDateStartingSelected: this.state.dateStartingSelected,
      installDateEndingSelected: this.state.dateEndingSelected,
    }

    if (filters.installed_at) {
      const value = filters.installed_at
      if (this.dateChoices.some(({ value: _val }) => _val !== 'Custom' && _val === value)) {
        installedAtParams['installDateSelected'] = value
      }
    } else if ('string' === typeof filters.installed_at_min && 'string' === typeof filters.installed_at_max) {
      const dateStartingSelected = dayjs.unix(filters.installed_at_min)
      const dateEndingSelected = dayjs.unix(filters.installed_at_max)
      console.log(dateStartingSelected, dateEndingSelected)
      if (dateStartingSelected.isValid() && dateEndingSelected.isValid()) {
        installedAtParams['installDateSelected'] = 'Custom'
        installedAtParams['installDateStartingSelected'] = dateStartingSelected
        installedAtParams['installDateEndingSelected'] = dateEndingSelected
      }
    }


    // todo 是否需要加入规则验证
    this.setState({
      ...installedAtParams,
      statusSelected: selectedDateFormat(filters.status || []),
      parcelpanelPlanSelected: selectedDateFormat(filters.parcelpanel_plan || []),
      shopifyPlanSelected: selectedDateFormat(filters.shopify_plan || []),
      referralLinkSelected: selectedDateFormat(filters.referral_link || []),
      queryValue: filters.query || '',
      tablePageNum: 1,
    }, () => {
      const queryParams = this.getQueryParams()

      console.log(tabId, queryParams)

      // this.props.router.navigate({ search: qs.stringify({ tab: tabId }) })

      // stores.ordersStore.setQueryParams(queryParams)
      this.fetchTableData(queryParams)
    })
  }

  /** 筛选项值改变事件处理 */
  handleQueryChange = (queryValue) => this.setState({ queryValue }, this.updateQueryParams)
  handleInstallDateChoiceListChange = (installDateSelected) => {
    this.setState(
      { installDateSelected },
      () => {
        // 选中 Custom 时不调用 更新参数的函数 节约资源
        'Custom' !== this.state.installDateSelected && this.updateQueryParams()
      },
    )
  }
  handleStartingChange = (installDateStartingSelected) => this.setState({ installDateStartingSelected }, this.updateQueryParams)
  handleEndingChange = (installDateEndingSelected) => this.setState({ installDateEndingSelected }, this.updateQueryParams)
  handleStatusChange = (statusSelected) => this.setState({ statusSelected }, this.updateQueryParams)
  handleParcelpanelPlanChange = (parcelpanelPlanSelected) => this.setState({ parcelpanelPlanSelected }, this.updateQueryParams)
  handleShopifyPlanChange = (shopifyPlanSelected) => this.setState({ shopifyPlanSelected }, this.updateQueryParams)
  handleReferralLinkChange = (referralLinkSelected) => this.setState({ referralLinkSelected }, this.updateQueryParams)
  handleSortByChange = (sortBySelected) => this.setState({ sortBySelected }, this.updateQueryParams)

  /**
   * 分页事件处理
   */
  handleTablePaginate = (page) => {
    this.setState(
      { tablePageNum: page },
      () => this.updateQueryParams2(),
    )
  }

  /** 移除筛选项 */
  handleInstallDateChoiceListRemove = () => this.setState({ installDateSelected: void 0 }, this.updateQueryParams)
  handleStatusRemove = () => this.setState({ statusSelected: [] }, this.updateQueryParams)
  handleParcelpanelPlanRemove = () => this.setState({ parcelpanelPlanSelected: [] }, this.updateQueryParams)
  handleShopifyPlanRemove = () => this.setState({ shopifyPlanSelected: [] }, this.updateQueryParams)
  handleReferralLinkRemove = () => this.setState({ referralLinkSelected: [] }, this.updateQueryParams)
  handleFiltersClearAll = () => this.getAppliedFilters().forEach(({
    key,
    onRemove,
  }) => onRemove(key))

  updateQueryParams = () => {
    this.setState({
      // 修改筛选项后页码回到首页
      tablePageNum: 1,
      // // 修改筛选项后 Save view 的状态置为未保存
      // navSaved: false,
    }, () => {
      const queryParams = this.getQueryParams()

      // stores.ordersStore.setQueryParams(queryParams)

      console.log(queryParams)

      this.props.history.push({
        // todo 清空参数，目前URL参数解析只解析了date
        // search: qs.stringify(queryParams, {
        search: qs.stringify({}, {
          skipNulls: true,
          arrayFormat: 'comma',
          allowDots: true,
        }),
      })

      this.fetchTableData(queryParams)
    })
  }

  updateQueryParams2 = () => {
    const queryParams = this.getQueryParams()

    // stores.ordersStore.setQueryParams(queryParams)
    this.setState({
      // 修改筛选项后 Save view 的状态置为未保存
      navSaved: false,
    })

    console.log(queryParams)

    this.props.history.push({
      // todo 清空参数，目前URL参数解析只解析了date
      // search: qs.stringify(queryParams, {
      search: qs.stringify({}, {
        skipNulls: true,
        arrayFormat: 'comma',
        allowDots: true,
      }),
    })

    this.fetchTableData(queryParams)
  }

  getQueryParams = () => {
    const {
      installDateSelected,
      installDateStartingSelected,
      installDateEndingSelected,
      statusSelected,
      parcelpanelPlanSelected,
      shopifyPlanSelected,
      referralLinkSelected,
      queryValue,
      tablePageNum,
      sortBySelected,
    } = this.state

    const params = {
      installed_at: void 0,
      installed_at_min: void 0,
      installed_at_max: void 0,
      status: statusSelected.length ? statusSelected : void 0,
      parcelpanel_plan: parcelpanelPlanSelected.length ? parcelpanelPlanSelected : void 0,
      shopify_plan: shopifyPlanSelected.length ? shopifyPlanSelected : void 0,
      referral_link: referralLinkSelected.length ? referralLinkSelected : void 0,
      query: queryValue || void 0,
      // order: sortBySelected ? '' || void 0,
      order_direction: sortBySelected || void 0,
      page: 1 < tablePageNum ? tablePageNum : void 0,
    }


    if ('Custom' !== installDateSelected) {
      const _selected = parseInt(installDateSelected)
      if (_selected) {
        params.installed_at_min = dayjs().subtract(_selected - 1, 'd')
      }
    } else {
      params.installed_at_min = installDateStartingSelected
      params.installed_at_max = installDateEndingSelected
    }

    params.installed_at_min = params.installed_at_min?.startOf('d').format()
    params.installed_at_max = params.installed_at_max?.endOf('d').format()

    return params
  }

  getTabs() {
    return this.tabs.map(v => ({
      id: `referrals-tabs-${ v.id }`,
      content: 'undefined' == typeof v.total ? v.content : `${ v.content } (${ v.total })`,
    }))
  }

  setTabs(tabs) {
    this.tabs.forEach(value => {
      const tab = tabs.find(v => v.id === value.id)
      value.total = tab ? tab.total : void 0
    })
  }
}

function selectedDateFormat(v) {
  return 'string' === typeof v ? [ v ] : v
}
