import request from '../utils/request'

export default {
  getFiltersOptions() {
    return request.get('referrals/get-filters-options')
  },

  getReferrals(data) {
    return request.post('referrals/get-referrals', data)
  },
}
