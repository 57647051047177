import axios from 'axios'
import memory from './memory'
import qs from 'qs'


let CancelTypesEnum;
(function (Role) {
  Role[Role['ALL'] = 0] = 'ALL'
  Role[Role['PATH'] = 1] = 'PATH'
})(CancelTypesEnum || (CancelTypesEnum = {}))

const request = axios.create({
  timeout: 1e4,
  baseURL: process.env.REACT_APP_BASE_API,
})

request.interceptors.request.use(
  request => {
    const extParam = {
      cancelType: request.data?.cancelType || CancelTypesEnum.ALL,
    }

    request.data && request.data?.cancelType && (delete request.data.cancelType)

    removePending(request, extParam)  // 在请求开始前，对之前的请求做检查取消操作
    addPending(request, extParam)  // 将当前请求添加到 pending 中

    request.headers['Authorization'] = `Bearer ${ memory.user_info.token || '' }`

    return request
  },
  error => {
    return Promise.reject(error)
  },
)

// 添加响应拦截器
request.interceptors.response.use(
  response => {
    // 关闭加载
    removePending(response.config)

    // 根据状态码判断不同的
    // const status = response?.status
    // const msg = response.data.msg

    // 如果后端说，不让接管 Snackbar 就直接返回数据了
    if (response?.data['__isShowSnackbar'] === false) {
      return response
    }


    // // todo 控制只弹出一个 toast
    // if (status !== APICode.OK) {
    //   toast(msg || 'Request error, please try again or refresh the page', 4e3, true)
    // }

    return response
  },
  error => {
    // 关闭加载
    if (axios.isCancel(error)) {
      // 中断 Promise 调用链
      return new Promise(() => void 0)
    }

    const response = error.response
    const status = response?.status

    console.error('出现错误了')

    // 根据返回的http状态码做不同的处理 response?.status
    // if (response?.status) {
    //   error.msg = ResponseErrorMessage[response.status] || `Error in connecting: ${ response.status }`
    // } else {
    //   error.msg = '连接错误, 请刷新页面'
    //   error.msg = 'Connection error, please refresh the page'
    // }

    // 弹出错误提示
    // toast(error.msg, 5e3, true)

    if (401 === status) {
      // todo 加 basename 会跳转失败
      window.location.href = '/login'
      // 中断 Promise 调用链
      return new Promise(() => void 0)
    }

    if (error.data) {
      error.data.status = 'fail'
      error.data.msg = error.msg
    }

    return Promise.reject(response || { message: error.message })
  },
)

/////////////////////////////////////////////////////////////
//////////////////////////取消重复请求配置/////////////////////
/////////////////////////////////////////////////////////////

// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map()

/**
 * 添加请求
 * @param {Object} config
 * @param extParam
 */
const addPending = (config, extParam) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
  ]

  // 如果取消的类型是 ALL 那么就要把 POST 参数加上
  if (extParam?.cancelType && extParam.cancelType === CancelTypesEnum.ALL) {
    url.push(qs.stringify(config.data))
  }

  let urlString = url.join('&')
  config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
    if (!pending.has(urlString)) {  // 如果 pending 中不存在当前请求，则添加进去
      pending.set(urlString, cancel)
    }
  })
}

/**
 * 移除请求
 * @param {Object} config
 * @param extParam
 */
const removePending = (config, extParam = void 0) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
  ]

  // 如果取消的类型是 ALL 那么就要把 POST 参数加上
  if (extParam?.cancelType && extParam.cancelType === CancelTypesEnum.ALL) {
    url.push(qs.stringify(config.data))
  }

  let urlString = url.join('&')
  if (pending.has(urlString)) {  // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(urlString)
    cancel(urlString)
    pending.delete(urlString)
  }
}

export default request
