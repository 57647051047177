import React, { useCallback, useEffect, useState } from 'react'
import { ChoiceList, FormLayout, Stack } from '@shopify/polaris'
import {
  TextDatePicker,
} from '../../../../components/TextDatePicker/TextDatePicker'


// 日期选择范围
// const _disableDatesBefore = dayjs().startOf('d').subtract(90, 'day')
//   , _disableDatesAfter = dayjs().startOf('d')

export function DateChoiceList({
  choices,
  selected,
  onChoiceListChange,
  startingSelected,
  endingSelected,
  onStartingChange,
  onEndingChange,
}) {

  const [ showCustom, setShowCustom ] = useState(false)

  // const disableDatesBefore = _disableDatesBefore.toDate()
  // const disableDatesAfter = _disableDatesAfter.toDate()

  const handleChoiceListChange = useCallback(
    (selected) => {
      // 交互体验：选择了 Custom 选项时还需要再选择日期才会触发列表筛选
      if ('Custom' !== selected[0]) {
        // 选择了非 Custom 选项才向上传递，
        // 不然会出现 appliedFilters 显示了日期范围的标签，却没更新列表的现象
        setShowCustom(false)
        onChoiceListChange(selected[0])
      } else {
        setShowCustom(true)
      }
    },
    [ onChoiceListChange ],
  )

  useEffect(() => {
    if ('undefined' == typeof selected) {
      setShowCustom(false)
    }
  }, [ selected ])

  return (
    <>
      <ChoiceList
        title={ null }
        choices={ choices }
        selected={ showCustom ? [ 'Custom' ] : selected ? [ selected ] : [] }
        onChange={ handleChoiceListChange }
      />
      {
        ('Custom' === selected || showCustom) && (
          <FormLayout>
            <Stack vertical>
              <TextDatePicker
                textFormat="YYYY-MM-DD"
                label="Starting"
                selected={ startingSelected }
                // disableDatesBefore={ disableDatesBefore }
                // disableDatesAfter={ disableDatesAfter }
                onChange={ (date) => {
                  'Custom' !== selected && onChoiceListChange('Custom')
                  onStartingChange(date)
                } }
              />
              <TextDatePicker
                textFormat="YYYY-MM-DD"
                label="Ending"
                selected={ endingSelected }
                // disableDatesBefore={ disableDatesBefore }
                // disableDatesAfter={ disableDatesAfter }
                onChange={ (date) => {
                  'Custom' !== selected && onChoiceListChange('Custom')
                  onEndingChange(date)
                } }
              />
            </Stack>
          </FormLayout>
        )
      }
    </>
  )
}
