import React, { Component } from 'react'
import { FormLayout, Select, TextField } from '@shopify/polaris'

export default class SignupStepTwo extends Component {

  handleInputChange = (val, name) => {
    this.props.onChange(this, name, val)
  }

  render() {
    return (
      <FormLayout>
        <Select
          id="partner_type"
          label="Partner type" value={ this.props.info.partner_type } placeholder="Select..."
          options={ this.props.info.partner_type_option }
          onChange={ this.handleInputChange }
          error={ this.props.info.partner_type_error }
        />
        <TextField
          id="promotion_plan"
          type="text" label="How do you plan to promote ParcelPanel?" multiline={ 4 }
          value={ this.props.info.promotion_plan }
          onChange={ this.handleInputChange }
          error={ this.props.info.promotion_plan_error }
          autoComplete="off"
        />
      </FormLayout>
    )
  }
}
