import React, { Component } from 'react'
import memory from '../../utils/memory'
import { Frame, Heading, Loading, Navigation, TopBar } from '@shopify/polaris'
import { BillingStatementDollarMajor, CustomersMajor, HomeMajor, LogOutMinor, ReferralMajor } from '@shopify/polaris-icons'
import logo from '../../assets/logo-m.svg'
import './Admin.scss'

export class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_info: {},
      isLoading: false,
      name: 'affiliate',
      userMenuActive: false,
      toggleUserMenuActive: false,
      // location: '/home',
    }
  }

  componentDidMount() {
    // if (this.props.location.pathname === '/') {
    //   // this.setState({ location: '/home' })
    // } else {
    //   this.setState({ location: this.props.location.pathname })
    // }
  }


  toHome = (e) => {
    e.preventDefault()
    this.props.history.push({
      pathname: '/',
    })
    // this.setState({ location: '/' })
  }


  toggleIsLoading = () => {
    // this.setState({ isLoading: !this.state.isLoading })
  }

  toggleUserMenuActive = () => {
    this.setState({ userMenuActive: !this.state.userMenuActive })
  }

  logout = () => {
    this.props.onLogout()
  }


  render() {
    const loadingMarkup = this.state.isLoading ? <Loading /> : null

    // Todo 暂时隐藏
    const searchFieldMarkup = null /*&& (
      <TopBar.SearchField
        onChange={ () => {} }
        value=""
        placeholder="Search"
        showFocusBorder
      />
    )*/


    const userMenuActions = [
      {
        // {
        //   url: '/',
        //   label: 'Dashboard',
        //   icon: HomeMajor,
        //   onClick: this.toggleIsLoading,
        //   exactMatch: true,
        // },
        // {
        //   url: '/referrals',
        //   label: 'Referrals',
        //   icon: ReferralMajor,
        //   onClick: this.toggleIsLoading,
        // },
        // {
        //   url: '/payouts',
        //   label: 'Payouts',
        //   icon: BillingStatementDollarMajor,
        //   onClick: this.toggleIsLoading,
        // },
        // {
        //   url: '/accounts',
        //   label: 'Account',
        //   icon: CustomersMajor,
        //   onClick: this.toggleIsLoading,
        // },
        items: [
          { url: '/',content: 'Dashboard', icon: HomeMajor },
          { url: '/referrals',content: 'Referrals', icon: ReferralMajor },
          { url: '/payouts',content: 'Payouts', icon: BillingStatementDollarMajor },
          { url: '/accounts',content: 'Account', icon: CustomersMajor },
          { content: 'Log out', icon: LogOutMinor, onAction: this.logout }
        ],
      },
    ]

    const userMenuMarkup = (
      <TopBar.UserMenu
        actions={userMenuActions}
        name={`${memory.user_info.first_name} ${memory.user_info.last_name}`}
        initials={memory.user_info.first_name ? `${memory.user_info.first_name.substring(0, 1)}${memory.user_info.last_name.substring(0, 1)}` : ''}
        open={this.state.userMenuActive}
        onToggle={this.toggleUserMenuActive}
      />
    )

    /*
    <TopBar.Menu activatorContent={ (
      <Link to="/home">
        <div className="ActivatorDetails">
          <img src={ logo } alt="ParcelPanel Affiliate"/>
          <Heading element="h1">ParcelPanel Affiliate</Heading>
        </div>
      </Link>
    ) } actions={ [] } open={ false } onOpen={ {} } onClose={ {} }/>
     */

    const topBarMarkup = (
      <TopBar
        contextControl={(
          <div className="_Switcher">
            {/*<Link*/}
            {/*  className ="_ActivatorLink"*/}
            {/*  removeUnderline*/}
            {/*  onClick={ this.toHome }*/}
            {/*>*/}
            {/*  <div className="ActivatorDetails">*/}
            {/*    <img*/}
            {/*      src={ logo }*/}
            {/*      alt="ParcelPanel Affiliate"*/}
            {/*    />*/}
            {/*    <Heading element="h1">ParcelPanel Affiliate</Heading>*/}
            {/*  </div>*/}
            {/*</Link>*/}
            {/* eslint-disable-next-line */}
            <a
              className="_ActivatorLink"
              onClick={this.toHome}
            >
              <div className="ActivatorDetails">
                <img
                  src={logo}
                  alt="ParcelPanel Affiliate"
                />
                <Heading element="h1">ParcelPanel Affiliate</Heading>
              </div>
            </a>
          </div>
        )}
        searchField={searchFieldMarkup}
        userMenu={userMenuMarkup}
      />
    )

    const isTip = JSON.parse(localStorage.getItem('user_info')).is_show_warning || ''

    const AccountText = (
        <div className='accountText'>Account<i className={isTip === 1 ? 'tip' : ''}/></div>
    )

    const navigationMarkup = (
      <Navigation location={this.props.location.pathname}>
        <Navigation.Section
          items={[
            {
              url: '/',
              label: 'Dashboard',
              icon: HomeMajor,
              onClick: this.toggleIsLoading,
              exactMatch: true,
            },
            {
              url: '/referrals',
              label: 'Referrals',
              icon: ReferralMajor,
              onClick: this.toggleIsLoading,
            },
            {
              url: '/payouts',
              label: 'Payouts',
              icon: BillingStatementDollarMajor,
              onClick: this.toggleIsLoading,
            },
            {
              url: '/accounts',
              label: AccountText ,
              icon: CustomersMajor,
              onClick: this.toggleIsLoading,
            },
          ]}
        />
      </Navigation>
    )

    // if (!memory.user_info) {
    //   return <Redirect to="/login"/>
    // }

    return (
      <Frame
        topBar={topBarMarkup}
        navigation={navigationMarkup}
      >
        {loadingMarkup}
        {this.props.children}
        {/*<Switch>*/}
        {/*  <Route exact path="/" component={ Home }/>*/}
        {/*  <Route path="/accounts" component={ Account }/>*/}
        {/*  <Route path="/payouts" component={ Payouts }/>*/}
        {/*</Switch>*/}
      </Frame>
    )
  }
}