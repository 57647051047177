import dayjs from 'dayjs'

/**
 * 校验是否符合某个日期格式规格
 * @param format  例如： YYYY-MM-DD
 * @param datetime  需要校验的时间
 */
const checkDatetimeFormat = (format = 'YYYY-MM-DD', datetime) => {
  let s = dayjs(datetime)
  if (s.format(format) === datetime) {
    return s
  }
  return null
}

export { checkDatetimeFormat }
