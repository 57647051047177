import { Card, TextStyle } from '@shopify/polaris'
import React from 'react'
import EChartsReact from 'echarts-for-react'
import styles from './InstallsClicksCard.module.scss'
import utils from '../../../../utils/utils'
import {
  CasingPly,
  SimpleTableList,
  SpinnerContainer,
} from '../../../../components'

export function InstallsClicksCard({
  loading,
  data = {},
  onViewDetailsClick,
}) {
  return (
    <Card
      title="Installs / Clicks"
      actions={[{
        content: 'View details',
        onAction: onViewDetailsClick,
      }]}
    >
      <InstallsClicksChart data={data} loading={loading} />
    </Card>
  )
}

function InstallsClicksChart({
  data = {},
  loading,
}) {
  const fontFamily = '-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif'
  const label = ['Installs', 'Clicks']

  const options = {
    // title: {
    //   text: '',
    //   bottom: 20,
    //   left: 16,
    //   textStyle: {
    //     color: '#637381',
    //     fontStyle: 'normal',
    //     fontWeight: 'normal',
    //     fontFamily,
    //     fontSize: 14,
    //   },
    // },
    grid: {
      y: 35,
      x: 20,
      x2: 20,
      containLabel: true,
      left: '0',  // 距离左边的距离
      right: '0',  // 距离右边的距离
      bottom: 50,
    },
    tooltip: {  // 触发类型：坐标轴触发
      confine: true, // 使得tooltip一直在容器中 而不会超出
      extraCssText: 'z-index: 2',
      trigger: 'axis',
      position: function (point, params, dom, rect, size) {
        // 其中point为当前鼠标的位置，size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
        const x = point[0]
        const y = point[1]
        // var viewWidth = size.viewSize[0];
        // var viewHeight = size.viewSize[1];
        const boxWidth = size.contentSize[0]
        const boxHeight = size.contentSize[1]
        let posX = 0  // x坐标位置
        let posY = 0  // y坐标位置

        if (x - 30 < boxWidth) {  // 左边放不开
          posX = 165
        } else {  // 左边放的下
          posX = x - boxWidth
        }

        if (y < boxHeight) {  // 上边放不开
          posY = 5
        } else {  // 上边放得下
          posY = y - boxHeight
        }
        return [posX, posY]
      },
      backgroundColor: 'rgba(0, 0, 0,0.8)',  // 通过设置rgba调节背景颜色与透明度
      color: 'black',
      borderWidth: '1',
      borderColor: 'gray',
      textStyle: {
        color: '#fff',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontFamily,
        fontSize: 14,
      },
    },
    legend: {  // 图例
      bottom: 0,
      right: 0,
      icon: 'roundRect',
      data: label,
      selectedMode: false,
      textStyle: {
        fontFamily,
        fontSize: 14,
      },
    },
    calculable: false,
    xAxis: [
      {
        type: 'category',
        boundaryGap: ['0', '0.1'],  // 距离坐标原点是否有间隙
        data: data.chart?.x_axis || [],  // X 轴数据
        axisLabel: {
          align: 'center',
          // showMaxLabel: true,
          textStyle: {
            color: '#637381',
            fontSize: '12',
            interval: 30,  // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
            rotate: 40,  // 横坐标上label的倾斜度
            fontFamily,
          },
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        minInterval: 1,
        // 改变x轴字体颜色和大小
        max: function (value) {
          if (value.max < 5) {
            return 5
          }
          return null
        },
        axisLabel: {
          textStyle: {
            color: '#637381',
            fontSize: '12',
            fontFamily,
          },
        },
      },
    ],
    series: [
      {
        name: label[0],
        type: 'line',
        symbol: 'circle',
        symbolSize: 1,  // 设定实心点的大小
        data: data.chart?.installs_data || [],
        emphasis: {
          lineStyle: { width: 3 },
        },
        markPoint: {
          label: {
            color: '#fff',  // markPoint 标记文字颜色设置为白色
          },
        },
        itemStyle: {
          normal: {
            lineStyle: { width: 3 },
            color: '#9B6EDB',
            fontFamily,
          },
        },
      },
      {
        name: label[1],
        type: 'line',
        symbol: 'circle',
        symbolSize: 1,  // 设定实心点的大小
        data: data.chart?.clicks_data || [],
        emphasis: {
          lineStyle: { width: 3 },
        },
        markPoint: {
          label: {
            color: '#fff',  // markPoint 标记文字颜色设置为白色
          },
        },
        itemStyle: {
          normal: {
            lineStyle: { width: 3 },
            color: '#47C1BF',
            fontFamily,
          },
        },
      },
    ],
  }


  const dataTable = () => {
    let {
      installs_count = '-',
      clicks_count = '-',
    } = data || {}

    let percentage1 = utils.getPercentage(installs_count, clicks_count)

    const fields = [
      { title: 'title', field: 'title' },
      {
        title: 'percentage',
        field: 'percentage',
        className: styles.AlignRight,
      },
    ],
      _data = [
        {
          title: 'Install rate',
          percentage: percentage1,
        },
      ]
    return (<SimpleTableList noHead fields={fields} data={_data} />)
  }

  const installsCount = data.installs_count || 0
  const clicksCount = data.clicks_count || 0

  const installsCountThousandsFormat = utils.toThousands(installsCount)
  const clicksCountThousandsFormat = utils.toThousands(clicksCount)

  return (
    <>
      <Card.Section>

        <CasingPly
          show={loading}
        >
          <p className={styles.CardSection__Allocation}>
            {installsCountThousandsFormat} / {clicksCountThousandsFormat}
          </p>
          {dataTable()}
          <p className={styles.Chart__Subhead}>
            <TextStyle variation="subdued">
              {label[0].toUpperCase()} / {label[1].toUpperCase()} OVER TIME
            </TextStyle>
          </p>
        </CasingPly>
        <SpinnerContainer
          loading={loading}
          spinnerClassName={['relative', '-top-20']}
        >
          <EChartsReact
            // className={ styles.CardSection__ReactECharts }
            style={{ height: '400px' }}
            option={options}
          />

        </SpinnerContainer>

      </Card.Section>
    </>
  )
}
