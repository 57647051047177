import React, { Component } from 'react'
import {
  Button,
  Card,
  Icon,
  Layout,
  SkeletonBodyText,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from '@shopify/polaris'
import './Home.scss'
import { homeApi } from '../../api'
import memory from '../../utils/memory'
import copy from 'copy-to-clipboard'
import { InstallsClicksCard, TableDatePicker } from './components'
import styles from './Home.module.scss'
import dayjs from 'dayjs'
import qs from 'qs'
import { HomeAirplane, HomeChat } from '../../assets/svg'
import { DEFAULT_COMMISSION_RATE } from '../../config/common'


export class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_info: memory.user_info,
      isLoading: true,

      conversionsInfo: {},

      datePicker: {},

      toast_active: false,

      referralLinkTypeList: [
        { type: 1, summary: 'Normal without extended trial' },
        {
          type: 2,
          summary: '7 days extended trial (collection time: up to 14 days)',
        },
        {
          type: 3,
          summary: '23 days extended trial (collection time: up to 30 days)',
        },
      ],

      referralLinkList: [],
    }
  }

  componentDidMount() {
    this.batchGetChart()
  }

  copyStr = (str) => {
    this.setState({ toast_active: true })
    copy(str)
  }

  onDismiss = () => {
    this.setState({ toast_active: false })
  }

  getCommissionRateText() {
    return `${ 100 * (memory.user_info.commission_rate || DEFAULT_COMMISSION_RATE) }%`
  }

  renderNoReferralsFound(emptyState) {
    // 从浏览器复制的结构
    // 增加了PP-前缀的类名
    return (
      <div className="PP-IndexTable--Empty Polaris-IndexTable">
        <table className="Polaris-IndexTable__Table Polaris-IndexTable__Table--unselectable">
          <thead>
          <tr>
            <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--second Polaris-IndexTable__TableHeading--unselectable">
              Store URL
            </th>
            <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--unselectable">Install
              date
            </th>
            <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--unselectable">Shopify
              plan
            </th>
            <th
              width="15%"
              className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--last Polaris-IndexTable__TableHeading--unselectable"
            >
              ParcelPanel plan
            </th>
          </tr>
          </thead>
        </table>
        <div className="PP-EmptySearch">
          { emptyState }
        </div>
      </div>
    )
  }

  render() {
    const {
      isLoading,
      installsClicksChartLoadedAt,
      installsClicksChartData,
    } = this.state

    const toastMarkup = this.state.toast_active ? (
      <Toast
        content="Link copied"
        onDismiss={ this.onDismiss }
        duration={ this.state.duration }
      />
    ) : null

    const conversionsInfo = this.state.conversionsInfo

    const referralLinkLabel = {
      1: 'Normal without extended trial',
      2: '7 days extended trial (collection time: up to 14 days)',
      3: '23 days extended trial (collection time: up to 30 days)',
    }

    const referralLinkMarkup = Object.keys(this.state.user_info.referral_link).map(k => {
      return (
        <TextField
          key={ k }
          type="text"
          value={ this.state.user_info.referral_link[k] }
          label={ referralLinkLabel[k] }
          autoComplete="off"
          connectedRight={
            <Button
              onClick={ () => {this.copyStr(this.state.user_info.referral_link[k])} }
              primary
            >Copy link</Button>
          }
        />
      )
    })

    const commissionRateText = this.getCommissionRateText()

    const step1Text = 'Copy the referral link and share it with other businesses.'
    const step2Text = `You can get ${ commissionRateText } lifetime commission of whatever the store registered through the referral link spends on a paid plan for ParcelPanel.`

    return (
      <div className="home page-warp">
        { toastMarkup }

        <TableDatePicker onChange={ this.handleTableDatePickerChange }/>

        <Layout>
          <Layout.Section>

            <Stack
              distribution="fillEvenly"
              spacing="loose"
            >
              <NumberCard
                loading={ isLoading }
                label="Total installs"
                value={ conversionsInfo.total_installed }
              />
              <NumberCard
                loading={ isLoading }
                label="Total paid"
                value={ conversionsInfo.total_subscript }
              />
              <NumberCard
                loading={ isLoading }
                label="Total uninstalls"
                value={ conversionsInfo.total_uninstalled }
              />
            </Stack>

          </Layout.Section>


          <Layout.Section>
            <Card title="Your referral link" sectioned>
              <Stack spacing="loose" vertical={ true }>
                { referralLinkMarkup }
              </Stack>
            </Card>

            <InstallsClicksCard
              data={ installsClicksChartData }
              loading={ !installsClicksChartLoadedAt }
              onViewDetailsClick={ this.handleInstallsClicksCardViewDetailsClick }
            />
          </Layout.Section>

          <Layout.Section oneThird>
            <Card title="How to get started?" sectioned>
              <TextContainer>
                <div className={ styles['TextContainer--spacing4'] }>
                  <TextStyle variation="strong">Step 1</TextStyle>
                  <p>{ step1Text }</p>
                </div>

                <div className={ styles['TextContainer--spacing4'] }>
                  <TextStyle variation="strong">Step 2</TextStyle>
                  <p>{ step2Text }</p>
                </div>
              </TextContainer>
            </Card>
            <Card title="Need any help?">
              <div className={ styles.HelpCenterWrapper }>
                <a
                  className={ styles.HelpCenterItem }
                  href="mailto:partner@parcelpanel.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={ styles.HelpCenterItem__Container }>
                    <div className={ styles.HelpCenterItem__IconWrapper }>
                      <Icon source={ HomeAirplane } color="base"/>
                    </div>
                    <div>
                      <h3 className={ styles.HelpCenterItem__Title }>
                        <TextStyle variation="strong">
                          Get Email Support
                        </TextStyle>
                      </h3>
                      <div>
                        Email us and we'll get back to you as soon as possible.
                      </div>
                    </div>
                  </div>
                </a>
                <div
                  className={ styles.HelpCenterItem }
                  onClick={ this.intercomShow }
                >
                  <div className={ styles.HelpCenterItem__Container }>
                    <div className={ styles.HelpCenterItem__IconWrapper }>
                      <Icon source={ HomeChat } color="base"/>
                    </div>
                    <div>
                      <h3 className={ styles.HelpCenterItem__Title }>
                        <TextStyle variation="strong">Start Live
                          Chat</TextStyle>
                      </h3>
                      <div>
                        Talk to us directly via live chat to get help with your
                        question.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Layout.Section>
        </Layout>
      </div>
    )
  }

  intercomShow = () => {
    window.Intercom('show')
  }

  handleTableDatePickerChange = (selected, startingDate, endingDate) => {
    let installed_at_min
      , installed_at_max

    if ('custom' !== selected) {
      const _selected = parseInt(selected)
      if (_selected) {
        installed_at_min = dayjs().subtract(_selected - 1, 'd')
      } else if ('all' === selected) {
        installed_at_max = dayjs()
      }
    } else {
      installed_at_min = startingDate
      installed_at_max = endingDate
    }

    this.setState({
      datePicker: {
        selected, startingDate, endingDate,
      },
    })

    this.batchGetChart(true, {
      installed_at_min: installed_at_min?.startOf('d').format(),
      installed_at_max: installed_at_max?.endOf('d').format(),
    })
  }

  handleInstallsClicksCardViewDetailsClick = () => {
    const {
      selected,
      startingDate,
      endingDate,
    } = this.state.datePicker

    const params = {}

    if ('custom' === selected) {
      params.installed_at_min = startingDate.format('YYYY-MM-DD')
      params.installed_at_max = endingDate.format('YYYY-MM-DD')
    } else if (parseInt(selected)) {
      params.installed_at = selected
    }

    this.props.history.push({
      pathname: '/referrals',
      search: qs.stringify(params, {
        skipNulls: true,
      }),
    })
  }

  batchGetChart = (focus, param) => {
    this.fetchConversionInfo(param)
    this.fetchInstallsClicksChart(focus, param)
  }

  fetchInstallsClicksChart = (focus, params) => {
    this.setState({
      installsClicksChartLoadedAt: 0,
    })
    // const shippingNotificationsDataStore = stores.shippingNotificationsDataStore
    // if (!focus && shippingNotificationsDataStore.loadedDataTime > 0) return
    // if (focus) shippingNotificationsDataStore.setLoadedDataTime(0)
    //
    // interface dataResponse extends BaseResponse {
    //   data?: ShippingNotifications
    // }

    homeApi.getInstallsClicksChart(params)
      .then(({ data: { data } }) => {
        // if (data.success) {
        //   console.warn('shippingNotifications: 获取数据失败', res)
        //   return
        // }

        console.info('shipmentsData: 获取数据成功', data)
        // shippingNotificationsDataStore.setLoadedDataTime(dayjs().unix())

        this.setState({
          installsClicksChartLoadedAt: dayjs().unix(),
          installsClicksChartData: data,
        })

        // data.data && shippingNotificationsDataStore.setData(data.data)
        !data && console.warn('InstallsClicksChart: 无 data 对象')
      })
  }

  fetchConversionInfo = (params) => {
    this.setState({
      isLoading: true,
    })
    homeApi.getConversionInfo(params)
      .then(({ data: { data } }) => {
        this.setState({
          conversionsInfo: data,
          isLoading: false,
        })
      })
  }
}

function NumberCard({
  loading,
  label,
  value,
}) {
  const skeletonMarkup = <SkeletonBodyText lines={ 3 }/>

  return (
    <Card sectioned>
      <div className="data-number">
        {
          loading
            ? skeletonMarkup
            : (
              <p>
                <span>{ value }</span>
                <span>{ label }</span>
              </p>
            )
        }
      </div>
    </Card>
  )
}
