import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import memory from './utils/memory'
import storage from './utils/storage'
import './assets/css/main.scss'
import { AppProvider } from '@shopify/polaris'
import enTranslations from '@shopify/polaris/locales/en.json'
import { ReactRouterLink } from './components'
import { BrowserRouter } from 'react-router-dom'

memory.user_info = storage.getInfo('user_info')
memory.setting = storage.getInfo('setting')

ReactDOM.render(<AppProvider linkComponent={ ReactRouterLink } i18n={ enTranslations }><BrowserRouter><App/></BrowserRouter></AppProvider>, document.getElementById('root'))