import { Spinner } from '@shopify/polaris'
import classNames from 'classnames'
import React from 'react'
import { BlockMaskLayer } from '../index'

export default function SpinnerContainer(props) {
  const _classNames = props.className ?? []
  const _spinnerClassName = props.spinnerClassName ?? []
  const _styles = props.style || {}

  return (
    (<BlockMaskLayer
      className={ _classNames } show={ props.loading }
      style={ { zIndex: 11, ..._styles } }
      maskNode={ (
        <div className={ classNames(_spinnerClassName) }>
          <Spinner hasFocusableParent={ false }/>
        </div>
      ) }
    >
      { props.children }
    </BlockMaskLayer>)
  )
}
