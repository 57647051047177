import React from 'react'
import { UnstyledLink } from '@shopify/polaris'
import styles from './NormalLink.module.scss'


export function NormalLink({
  url,
  children,
  external,
  id,
}) {
  return (
    <UnstyledLink
      className={ styles.NormalLink }
      url={ url }
      external={ external }
      id={ id }
    >
      { children }
    </UnstyledLink>
  )
}
