import _ from 'lodash'
import React from 'react'
import styles from './SimpleTableList.module.scss'
import classNames from 'classnames'
import utils from '../../utils/utils'

export default function SimpleTableList(props) {
  const fields = _.map(props.fields, 'field')

  const classNameMap = new Map()
  props.fields.map(item => classNameMap.set(item.field, item.className ?? ''))

  // 空数据设置
  let emptyData
  if (props.data.length === 0 && props.emptyData) {
    emptyData = (
      <SimpleTableListItem
        fields={ fields } values={ props.emptyData } key={ 'head-0' }
        classNameMap={ classNameMap }
      />
    )
  }

  let thead
  if (!props.noHead) {
    thead = (
      <thead>
      <tr>
        {
          props.fields.map(
            (item) => (
              <th
                style={ { paddingRight: 0 } }
                key={ `${ item.field }` }
                className={ item.className }
              >
                { item.title }
              </th>
            ),
          )
        }
      </tr>
      </thead>
    )
  } else {
    thead = <></>
  }

  // 最终渲染输出
  return (
    <table className={ `${ styles.table } ${ props.className }` }>
      { thead }
      <tbody>
      {
        props.data.map(
          (values, index) => (
            <SimpleTableListItem
              thousandsSplitFields={ props.thousandsSplitFields || [] }
              fields={ fields }
              values={ values }
              key={ index + '' }
              classNameMap={ classNameMap }
            />
          ),
        )
      }
      { emptyData }
      </tbody>
    </table>
  )
}


function SimpleTableListItem(props) {
  return (
    <tr>
      {
        props.fields.map((field, index) => {
          let val = props.values[field]
          if (_.includes(props.thousandsSplitFields, field) && _.isNumber(val)) val = utils.toThousands(val)
          return (
            <td
              style={ { paddingRight: 0 } }
              key={ `td-${ field }-${ index }` }
              className={ classNames(props.classNameMap.get(field), 'leading-5') }
            >
              { val }
            </td>
          )
        })
      }
    </tr>
  )
}
