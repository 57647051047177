import React, { Component } from 'react'
import { Badge, Card, EmptySearchResult, IndexTable, Pagination, SkeletonBodyText, Stack, TextStyle } from '@shopify/polaris'
import './Payouts.scss'
import { reqPaymentDetail, reqPayoutsTotalInfo } from '../../api/index'
import dayjs from 'dayjs'
import memory from '../../utils/memory'
import { DEFAULT_COMMISSION_RATE } from '../../config/common'


export class Payouts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      response_data: [],
      offset: 1,
      total_num: 0,
      to: 0,
      pagination: {
        prevPage: void 0,
        nextPage: void 0,
      },

      payoutsTotalInfo: {},

      payoutsRecords: [],
      payoutsRecordsLoading: true,
      payoutsRecordsTotalNum: 0,

      payoutsInfo: {},
    }
  }

  fetchData = () => {
    this.setState({ payoutsRecordsLoading: true })

    const offset = 1 < this.state.offset ? this.state.offset : void 0

    const nowPage = 1 < offset ? offset : 1
    const prevPage = nowPage - 1
    const nextPage = nowPage + 1

    reqPaymentDetail({
      page: offset,
    }).then(
      resp => {
        const page = resp.data.data.page
        const data = resp.data.data.data

        const pagination = {
          prevPage: page.has_previous ? prevPage : void 0,
          nextPage: page.has_next ? nextPage : void 0,
        }

        this.setState({
          pagination,
          payoutsRecordsTotalNum: page.total,

          payoutsRecords: data,

          payoutsRecordsLoading: false,
        })
      },
    ).catch(
      err => {
        if (401 === err.response?.status) {
          this.props.history.replace('/login')
        }
      },
    )
  }

  componentDidMount() {
    this.fetchData()
    reqPayoutsTotalInfo().then(
      resp => {
        this.setState({ payoutsTotalInfo: resp.data.data, isLoading: false })
      },
    ).catch(
      error => {
        if (401 === error.response?.status) {
          this.props.history.replace('/login')
        }
      },
    )
  }

  // componentWillUnmount() {
  //   this.setState = () => {}
  // }

  next = () => {
    this.setState({ offset: this.state.pagination.nextPage }, this.fetchData)
  }

  prev = () => {
    this.setState({ offset: this.state.pagination.prevPage }, this.fetchData)
  }

  getBillingDate(date) {
    const startOfMonth = dayjs(date, 'YYYY-MM').startOf('month').format('MMM D, YYYY')
    const endOfMonth = dayjs(date, 'YYYY-MM').endOf('month').format('MMM D, YYYY')
    return `${ startOfMonth } - ${ endOfMonth }`
  }

  getCommissionRateText() {
    return `${ 100 * (memory.user_info.commission_rate || DEFAULT_COMMISSION_RATE) }%`
  }

  renderNoRecordsFound(emptyState) {
    // 从浏览器复制的结构
    // 增加了PP-前缀的类名
    return (
      <div className="PP-IndexTable--Empty Polaris-IndexTable">
        <table className="Polaris-IndexTable__Table Polaris-IndexTable__Table--unselectable">
          <thead>
          <tr>
            <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--second Polaris-IndexTable__TableHeading--unselectable">
              <div className="Table-th-box">Billing date of referred stores</div>
            </th>
            <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--unselectable">Payment date</th>
            <th className="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--last Polaris-IndexTable__TableHeading--unselectable">
              Payout amount
            </th>
          </tr>
          </thead>
        </table>
        <div className="PP-EmptySearch">
          { emptyState }
        </div>
      </div>
    )
  }

  render() {
    const payoutsInfo = this.state.payoutsTotalInfo

    // 定义空值状态
    const emptyStateMarkup = <EmptySearchResult title="No records found" withIllustration/>

    const skeletonMarkup = <SkeletonBodyText lines={ 3 }/>

    const payoutsRecordsRowMarkup = this.state.payoutsRecords.map(
      ({ id, month, created_at, paid_at, payout }, index) => (
        <IndexTable.Row id={ id } key={ id } position={ index }>
          <IndexTable.Cell><div className="Payout-date-table-box">{ this.getBillingDate(month) }</div></IndexTable.Cell>
          <IndexTable.Cell>
            {
              paid_at
                ? <Badge status="success">{ dayjs(paid_at).format('[Sent on] MMM D, YYYY') }</Badge>
                : <Badge>Pending</Badge>
            }
          </IndexTable.Cell>
          <IndexTable.Cell>${ payout }</IndexTable.Cell>
        </IndexTable.Row>
      ),
    )

    const payoutsRecordsMarkup = (
      <div className="PayoutsRecords PP-Table">
        <Card>
          {
            (this.state.payoutsRecordsLoading || 0 < this.state.payoutsRecords.length)
              ? (
                <IndexTable
                  headings={ [
                    { title: 'Billing date of referred stores' },
                    { title: 'Payment date' },
                    { title: 'Payout amount' },
                  ] }
                  itemCount={ this.state.payoutsRecords.length }
                  selectable={ false }
                  emptyState={ emptyStateMarkup }
                  loading={ this.state.payoutsRecordsLoading }
                >
                  { payoutsRecordsRowMarkup }
                </IndexTable>
              )
              : (
                this.renderNoRecordsFound(emptyStateMarkup)
              )
          }

          {
            0 < this.state.payoutsRecords.length && (
              <Card.Section>
                <div className="pagination-section">
                  <Pagination
                    hasPrevious={ !!this.state.pagination.prevPage }
                    onPrevious={ this.prev }
                    hasNext={ !!this.state.pagination.nextPage }
                    onNext={ this.next }
                  />
                </div>
              </Card.Section>
            )
          }
        </Card>

        <div className="Pagination__Section">
          <span className="pagination-text">{ `Showing ${ this.state.payoutsRecords.length } of ${ this.state.payoutsRecordsTotalNum } results` }</span>
        </div>
      </div>
    )

    const commissionRateText = (
      <TextStyle variation="strong">{ this.getCommissionRateText() }</TextStyle>
    )

    return (
      <div className="page-warp">
        <h1 className="page-title">Payouts</h1>

        <div className="section">
          <div className="section-header">
            <div className="section-header__TitleWrapper">
              <h2 className="section-header-title">Total number</h2>
            </div>
          </div>
          <div className="section-content">
            <Stack
              distribution="fillEvenly"
              spacing="loose"
            >
              <Card sectioned>
                <div className="data-number">
                  {
                    undefined === payoutsInfo.payout
                      ? skeletonMarkup
                      : (
                        <p>
                          <span>${ payoutsInfo.payout }</span>
                          <span>Payout</span>
                        </p>
                      )
                  }
                </div>
              </Card>

              <Card sectioned>
                <div className="data-number">
                  {
                    undefined === payoutsInfo.pending
                      ? skeletonMarkup
                      : (
                        <p>
                          <span>${ payoutsInfo.pending }</span>
                          <span>Pending</span>
                        </p>
                      )
                  }
                </div>
              </Card>

              <Card sectioned>
                <div className="data-number">
                  {
                    undefined === payoutsInfo.total_earned
                      ? skeletonMarkup
                      : (
                        <p>
                          <span>${ payoutsInfo.total_earned }</span>
                          <span>Total earned</span>
                        </p>
                      )
                  }
                </div>
              </Card>
            </Stack>
          </div>
        </div>

        <div className="section">
          <div className="section-header">
            <div className="section-header__TitleWrapper">
              <h2 className="section-header-title">Payouts records</h2>
              <p className="section-header-subtitle">
                <TextStyle variation="subdued">
                  You can get { commissionRateText } commission of whatever the store
                  registered through the referral link spends on a paid plan for
                  ParcelPanel.
                </TextStyle>
              </p>
            </div>
          </div>
          <div className="section-content">
            { payoutsRecordsMarkup }
            <div className="PayoutsRecords-BottomTip">
              <Stack spacing="none" wrap={ false }>
                <Stack.Item>
                  <span className="PayoutsRecords-BottomTip__Title">Note:&nbsp;</span>
                </Stack.Item>
                <Stack.Item fill="true">
                  {
                    [
                      '1. Commissions earned in the current month will be settled and paid between the 10th - 20th of the next month.',
                      '2. We only settle and pay commissions when the accumulated amount reaches or exceeds $25.',
                      '3. Please note that commissions are settled after deducting any fees imposed by Shopify.',
                      '4. Commissions for annual plans will be settled and paid on a monthly basis.'
                    ].map(v => (
                      <p className="PayoutsRecords-BottomTip__Item">{ v }</p>
                    ))
                  }
                </Stack.Item>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

